import { api } from '../baseApi';
const ocpp16Api = api.injectEndpoints({
  endpoints: (build) => ({
    CancelReservation: build.mutation<
      CancelReservationRes,
      CancelReservationReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/cancel-reservation`,
        method: 'POST',
        body: queryArg.cancelReservationCmdDto
      })
    }),
    ChangeAvailability: build.mutation<
      ChangeAvailabilityRes,
      ChangeAvailabilityReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/change-availability`,
        method: 'POST',
        body: queryArg.changeAvailabilityCmdDto
      })
    }),
    ChangeConfiguration: build.mutation<
      ChangeConfigurationRes,
      ChangeConfigurationReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/change-configuration`,
        method: 'POST',
        body: queryArg.changeConfigurationCmdDto
      })
    }),
    ClearCache: build.mutation<ClearCacheRes, ClearCacheReq>({
      query: (queryArg) => ({ url: `/ocpp/16/command/${queryArg.chargePointId}/clear-cache`, method: 'POST' })
    }),
    ClearChargingProfile: build.mutation<
      ClearChargingProfileRes,
      ClearChargingProfileReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/clear-charging-profile`,
        method: 'POST',
        body: queryArg.clearChargingProfileCmdDto
      })
    }),
    DataTransfer: build.mutation<DataTransferRes, DataTransferReq>({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/data-transfer`,
        method: 'POST',
        body: queryArg.dataTransferCmdDto
      })
    }),
    GetCompositeSchedule: build.mutation<
      GetCompositeScheduleRes,
      GetCompositeScheduleReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/get-composite-schedule`,
        method: 'POST',
        body: queryArg.getCompositeScheludeCmdDto
      })
    }),
    GetConfiguration: build.mutation<
      GetConfigurationRes,
      GetConfigurationReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/get-configuration`,
        method: 'POST',
        body: queryArg.getConfigurationCmdDto
      })
    }),
    GetDiagnostics: build.mutation<
      GetDiagnosticsRes,
      GetDiagnosticsReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/get-diagnostics`,
        method: 'POST',
        body: queryArg.getDiagnosticsCmdDto
      })
    }),
    GetLocalListVersion: build.mutation<
      GetLocalListVersionRes,
      GetLocalListVersionReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/get-local-list-version`,
        method: 'POST'
      })
    }),
    RemoteStartTransaction: build.mutation<
      RemoteStartTransactionRes,
      RemoteStartTransactionReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/remote-start-transaction`,
        method: 'POST',
        body: queryArg.remoteStartTransactionCmdDto
      })
    }),
    RemoteStopTransaction: build.mutation<
      RemoteStopTransactionRes,
      RemoteStopTransactionReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/remote-stop-transaction`,
        method: 'POST',
        body: queryArg.remoteStopTransactionCmdDto
      })
    }),
    ReserveNow: build.mutation<ReserveNowRes, ReserveNowReq>({
      query: (queryArg) => ({ url: `/ocpp/16/command/${queryArg.chargePointId}/reserve-now`, method: 'POST' })
    }),
    Reset: build.mutation<ResetRes, ResetReq>({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/reset`,
        method: 'POST',
        body: queryArg.resetCmdDto
      })
    }),
    SendLocalList: build.mutation<
      SendLocalListRes,
      SendLocalListReq
    >({
      query: (queryArg) => ({ url: `/ocpp/16/command/${queryArg.chargePointId}/send-local-list`, method: 'POST' })
    }),
    SetChargingProfile: build.mutation<
      SetChargingProfileRes,
      SetChargingProfileReq
    >({
      query: (queryArg) => ({ url: `/ocpp/16/command/${queryArg.chargePointId}/set-charging-profile`, method: 'POST' })
    }),
    TriggerMessage: build.mutation<
      TriggerMessageRes,
      TriggerMessageReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/trigger-message`,
        method: 'POST',
        body: queryArg.triggerMessageCmdDto
      })
    }),
    UnlockConnector: build.mutation<
      UnlockConnectorRes,
      UnlockConnectorReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/unlock-connector`,
        method: 'POST',
        body: queryArg.unlockConnectorCmdDto
      })
    }),
    UpdateFirmware: build.mutation<
      UpdateFirmwareRes,
      UpdateFirmwareReq
    >({
      query: (queryArg) => ({
        url: `/ocpp/16/command/${queryArg.chargePointId}/update-firmware`,
        method: 'POST',
        body: queryArg.updateFirmwareCmdDto
      })
    })
  }),
  overrideExisting: false
});
export { ocpp16Api as enhancedApi };
export type CancelReservationRes = /** status 200  */ object;
export type CancelReservationReq = {
  chargePointId: string;
  cancelReservationCmdDto: CancelReservationCmdDto;
};
export type ChangeAvailabilityRes = /** status 200  */ object;
export type ChangeAvailabilityReq = {
  chargePointId: string;
  changeAvailabilityCmdDto: ChangeAvailabilityCmdDto;
};
export type ChangeConfigurationRes = /** status 200  */ object;
export type ChangeConfigurationReq = {
  chargePointId: string;
  changeConfigurationCmdDto: ChangeConfigurationCmdDto;
};
export type ClearCacheRes = /** status 200  */ object;
export type ClearCacheReq = {
  chargePointId: string;
};
export type ClearChargingProfileRes = /** status 200  */ object;
export type ClearChargingProfileReq = {
  chargePointId: string;
  clearChargingProfileCmdDto: ClearChargingProfileCmdDto;
};
export type DataTransferRes = /** status 200  */ object;
export type DataTransferReq = {
  chargePointId: string;
  dataTransferCmdDto: DataTransferCmdDto;
};
export type GetCompositeScheduleRes = /** status 200  */ object;
export type GetCompositeScheduleReq = {
  chargePointId: string;
  getCompositeScheludeCmdDto: GetCompositeScheludeCmdDto;
};
export type GetConfigurationRes = /** status 200  */ object;
export type GetConfigurationReq = {
  chargePointId: string;
  getConfigurationCmdDto: GetConfigurationCmdDto;
};
export type GetDiagnosticsRes = /** status 200  */ object;
export type GetDiagnosticsReq = {
  chargePointId: string;
  getDiagnosticsCmdDto: GetDiagnosticsCmdDto;
};
export type GetLocalListVersionRes = /** status 200  */ object;
export type GetLocalListVersionReq = {
  chargePointId: string;
};
export type RemoteStartTransactionRes = unknown;
export type RemoteStartTransactionReq = {
  chargePointId: string;
  remoteStartTransactionCmdDto: RemoteStartTransactionCmdDto;
};
export type RemoteStopTransactionRes = /** status 200  */ object;
export type RemoteStopTransactionReq = {
  chargePointId: string;
  remoteStopTransactionCmdDto: RemoteStopTransactionCmdDto;
};
export type ReserveNowRes = /** status 200  */ boolean;
export type ReserveNowReq = {
  chargePointId: string;
};
export type ResetRes = /** status 200  */ object;
export type ResetReq = {
  chargePointId: string;
  resetCmdDto: ResetCmdDto;
};
export type SendLocalListRes = /** status 200  */ boolean;
export type SendLocalListReq = {
  chargePointId: string;
};
export type SetChargingProfileRes = /** status 200  */ boolean;
export type SetChargingProfileReq = {
  chargePointId: string;
};
export type TriggerMessageRes = /** status 200  */ object;
export type TriggerMessageReq = {
  chargePointId: string;
  triggerMessageCmdDto: TriggerMessageCmdDto;
};
export type UnlockConnectorRes = /** status 200  */ object;
export type UnlockConnectorReq = {
  chargePointId: string;
  unlockConnectorCmdDto: UnlockConnectorCmdDto;
};
export type UpdateFirmwareRes = /** status 200  */ object;
export type UpdateFirmwareReq = {
  chargePointId: string;
  updateFirmwareCmdDto: UpdateFirmwareCmdDto;
};
export type CancelReservationCmdDto = {};
export type ChangeAvailabilityCmdDto = {
  connectorId: number;
  type: 'Inoperative' | 'Operative';
};
export type ChangeConfigurationCmdDto = {
  messageId?: string;
  key: string;
  value: object;
};
export type ClearChargingProfileCmdDto = {
  id: number;
  connectorId: number;
  chargingProfilePurpose: 'ChargePointMaxProfile' | 'TxDefaultProfile' | 'TxProfile' | 'NotSupported';
  stackLevel: number;
};
export type DataTransferCmdDto = {
  vendorId: string;
  messageId: string;
  data: string;
};
export type GetCompositeScheludeCmdDto = {
  connectorId: number;
  duration: number;
  chargingRateUnit: 'A' | 'W';
};
export type GetConfigurationCmdDto = {
  messageId?: string;
  key?: string;
};
export type GetDiagnosticsCmdDto = {
  location: string;
  retries: number;
  retryInterval: number;
  startTime: string;
  stopTime: string;
};
export type RemoteStartTransactionCmdDto = {
  messageId?: string;
  connectorId: number;
  customer: string;
  addressId: string;
  cardToken: string;
};
export type RemoteStopTransactionCmdDto = {
  messageId?: string;
  transactionId: number;
};
export type ResetCmdDto = {
  type: 'Hard' | 'Soft';
};
export type TriggerMessageCmdDto = {
  requestedMessage: string;
  connectorId: number;
};
export type UnlockConnectorCmdDto = {
  messageId?: string;
  connectorId: number;
};
export type UpdateFirmwareCmdDto = {
  location: string;
  retries: number;
  retryInterval: number;
  retrieveDate: string;
};
export const {
  useCancelReservationMutation,
  useChangeAvailabilityMutation,
  useChangeConfigurationMutation,
  useClearCacheMutation,
  useClearChargingProfileMutation,
  useDataTransferMutation,
  useGetCompositeScheduleMutation,
  useGetConfigurationMutation,
  useGetDiagnosticsMutation,
  useGetLocalListVersionMutation,
  useRemoteStartTransactionMutation,
  useRemoteStopTransactionMutation,
  useReserveNowMutation,
  useResetMutation,
  useSendLocalListMutation,
  useSetChargingProfileMutation,
  useTriggerMessageMutation,
  useUnlockConnectorMutation,
  useUpdateFirmwareMutation
} = ocpp16Api;
