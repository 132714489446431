import { lazy } from 'react';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import { AUTH_ROUTES } from 'types/routes';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthChangePassword = Loadable(lazy(() => import('pages/auth/change-password')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/',
          element: <AuthLogin />
        },
        {
          path: AUTH_ROUTES.LOGIN,
          element: <AuthLogin />
        },
        {
          path: AUTH_ROUTES.REGISTER,
          element: <AuthRegister />
        },
        {
          path: AUTH_ROUTES.CHANGE_PASSWORD,
          element: <AuthChangePassword />
        },
        {
          path: AUTH_ROUTES.FORGOT_PASSWORD,
          element: <AuthForgotPassword />
        },
        {
          path: AUTH_ROUTES.CHECK_MAIL,
          element: <AuthCheckMail />
        },
        {
          path: AUTH_ROUTES.RESET_PASSWORD,
          element: <AuthResetPassword />
        },
        {
          path: AUTH_ROUTES.CODE_VERIFICATION,
          element: <AuthCodeVerification />
        }
      ]
    }
  ]
};

export default AuthRoutes;
