import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { CPO_ROUTES, MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const CpoList = Loadable(lazy(() => import('pages/cpo/screens/list/CpoList')));
const CpoCreate = Loadable(lazy(() => import('pages/cpo/screens/create/CpoCreate')));
const CpoEdit = Loadable(lazy(() => import('pages/cpo/screens/edit/CpoEdit')));
const CpoDetail = Loadable(lazy(() => import('pages/cpo/screens/detail/CpoDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const CpoRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <MainLayout />
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.CPOS,
      children: [
        {
          path: CPO_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CPO_READ]}>
              <CpoList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CPO_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CPO_WRITE]}>
              <CpoCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CPO_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CPO_WRITE]}>
              <CpoEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CPO_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CPO_READ]}>
              <CpoDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default CpoRoutes;
