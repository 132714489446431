import usePermission from 'hooks/usePermission';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Roles } from 'types/roles';

interface Props {
  accessibleRoles: Roles[] | undefined;
  children: React.ReactNode;
}

export function RoleBasedComponentGuard({ accessibleRoles, children }: Props) {
  const { roles } = usePermission();

  if (!accessibleRoles) return <>{children}</>;

  if (!accessibleRoles.some((role) => roles.includes(role))) {
    return null;
  }

  return <>{children}</>;
}

export function RoleBasedRouteGuard({ accessibleRoles, children }: Props) {
  const { roles } = usePermission();

  if (!accessibleRoles) return <>{children}</>;

  if (!accessibleRoles.some((role) => roles.includes(role))) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}

interface UseRolesBasedGuardProps {
  accessibleRoles: Roles[] | Roles | undefined;
}

export function useRolesBasedGuard({ accessibleRoles }: UseRolesBasedGuardProps) {
  const { roles } = usePermission();
  const [access, setAccess] = useState(false);

  useEffect(() => {
    if (!accessibleRoles) return setAccess(true);
    if (Array.isArray(accessibleRoles)) {
      return setAccess(accessibleRoles.some((role) => roles.includes(role)));
    } else {
      return setAccess([accessibleRoles].some((role) => roles.includes(role)));
    }
  }, [accessibleRoles, roles]);

  return access;
}

export function RootComponentGuard({ children }: Pick<Props, 'children'>) {
  const { isRoot, permissionsInitialized } = usePermission();

  if (!permissionsInitialized) return null;

  if (!isRoot) {
    return null;
  }

  return <>{children}</>;
}

export function RootRouteGuard({ children }: Pick<Props, 'children'>) {
  const { isRoot, permissionsInitialized } = usePermission();

  if (!permissionsInitialized) return null;

  if (!isRoot) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
