import { FormattedMessage } from 'react-intl';
import {
  UngroupOutlined,
  ThunderboltOutlined,
  MoneyCollectOutlined,
  CarryOutOutlined,
  // ProfileOutlined,
  UserOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import { NavItemType } from 'types_/state/menu';
import { Roles } from 'types/roles';

const cpo: NavItemType = {
  id: 'cpo',
  title: <FormattedMessage id="cpo-group-title" />,
  type: 'group',
  children: [
    {
      id: 'charge-points',
      title: <FormattedMessage id="charge-points-page-child-title" />,
      url: '/charge-points/list',
      type: 'item',
      icon: UngroupOutlined,
      withCpoId: true,
      accessibleRoles: [Roles.CHARGE_POINT_READ]
    },
    {
      id: 'locations',
      title: <FormattedMessage id="locations-page-child-title" />,
      type: 'item',
      url: '/locations/list',
      icon: EnvironmentOutlined,
      withCpoId: true,
      accessibleRoles: [Roles.LOCATION_READ]
    },
    {
      id: 'charge-operations',
      title: <FormattedMessage id="charge-operations-page-child-title" />,
      type: 'item',
      url: '/charge-operations/list',
      icon: ThunderboltOutlined,
      withCpoId: true,
      accessibleRoles: [Roles.CHARGE_OPERATIONS_READ]
    },

    {
      id: 'tariffs',
      title: <FormattedMessage id="tariffs-page-child-title" />,
      type: 'item',
      url: '/tariffs/list',
      icon: MoneyCollectOutlined,
      withCpoId: true,
      accessibleRoles: [Roles.TARIFF_READ]
    },
    // {
    //   id: 'delegation',
    //   title: <FormattedMessage id="delegation-page-child-title" />,
    //   type: 'item',
    //   url: '/delegation/list',
    //   icon: ProfileOutlined
    // },
    {
      id: 'reservation',
      title: <FormattedMessage id="reservation-page-child-title" />,
      type: 'item',
      url: '/reservations/list',
      icon: CarryOutOutlined,
      withCpoId: true,
      accessibleRoles: [Roles.RESERVATION_READ]
    },
    {
      id: 'users',
      title: <FormattedMessage id="users-page-child-title" />,
      type: 'item',
      url: '/users/list',
      icon: UserOutlined,
      withCpoId: true,
      accessibleRoles: [Roles.USER_READ]
    }
  ]
};

export default cpo;
