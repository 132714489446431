import { FormattedMessage } from 'react-intl';
import { Typography, TypographyProps } from '@mui/material';

export type LocalizedTypographyProps = TypographyProps & {
  locKey: string;
  values?: Record<string, React.ReactNode>;
};

const LocalizedTypography = ({ locKey, values, variant = 'h6', ...rest }: LocalizedTypographyProps) => {
  return (
    <Typography variant={variant} {...rest}>
      <FormattedMessage id={locKey} values={values} />
    </Typography>
  );
};

export default LocalizedTypography;
