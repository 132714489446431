import { MiddlewareAPI, Middleware, isFulfilled } from '@reduxjs/toolkit';
import { resetLoading } from 'store/reducers/globalUI';
import { openSnackbar } from 'store/reducers/snackbar';
import { successToastKeys, showOnlySuccessToastEndpoints, showToastEndpoints } from './ActionConstants';

export const SuccessMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isFulfilled(action)) {
    const actionName = action.meta.arg.endpointName;
    const isActionHasSuccessToast =
      showToastEndpoints.some((endpoint) => endpoint === actionName) ||
      showOnlySuccessToastEndpoints.some((endpoint) => endpoint === actionName);

    api.dispatch(resetLoading());

    if (isActionHasSuccessToast) {
      api.dispatch(
        openSnackbar({
          open: true,
          message: successToastKeys[actionName],
          variant: 'alert',
          alert: {
            color: 'success',
            severity: 'success'
          },
          close: true
        })
      );
    }
  }

  return next(action);
};
