import { NavItemType } from 'types_/state/menu';
import cpo from './cpo';
import eMsp from './eMsp';
// import notifications from './notifications';
import content from './content';
import dashboard from './dashboard';
/* import settings from './settings'; */
import epdk from './epdk';

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, cpo, eMsp, content, epdk /* settings */]
};

export default menuItems;
