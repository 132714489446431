import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootStateProps } from 'types_/state/root';

export default function BackdropLoading() {
  const theme = useTheme();

  const { isLoading } = useSelector((state: RootStateProps) => state.globalUI);

  return (
    <Backdrop
      sx={{ color: theme.palette.success.main, zIndex: theme.zIndex.drawer + 1, backdropFilter: 'blur(2px)' }}
      open={isLoading}
    >
      <CircularProgress color="success" size={80} thickness={5} />
    </Backdrop>
  );
}
