import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { MAIN_ROUTES, HARDWARE_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const HardwareList = Loadable(lazy(() => import('pages/hardware/screens/list/HardwareList')));
const HardwareCreate = Loadable(lazy(() => import('pages/hardware/screens/create/HardwareCreate')));
const HardwareEdit = Loadable(lazy(() => import('pages/hardware/screens/edit/HardwareEdit')));
const HardwareDetail = Loadable(lazy(() => import('pages/hardware/screens/detail/HardwareDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const HardwareRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <MainLayout />
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.HARDWARES,
      children: [
        {
          path: HARDWARE_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.HARDWARE_READ]}>
              <HardwareList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: HARDWARE_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.HARDWARE_WRITE]}>
              <HardwareCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: HARDWARE_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.HARDWARE_WRITE]}>
              <HardwareEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: HARDWARE_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.HARDWARE_READ]}>
              <HardwareDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default HardwareRoutes;
