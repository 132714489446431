// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';

// auth provider
import { AWSCognitoProvider as AuthProvider } from 'contexts/CognitoContext';
import { PermissionProvider } from 'contexts/PermissionContext';
import BackdropLoading from 'components/loading/BackdropLoading';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ThemeCustomization>
    {/* <RTLLayout> */}
    <Locales>
      <ScrollTop>
        <AuthProvider>
          <PermissionProvider>
            <>
              <Routes />
              <BackdropLoading />
              <Snackbar />
            </>
          </PermissionProvider>
        </AuthProvider>
      </ScrollTop>
    </Locales>
    {/* </RTLLayout> */}
  </ThemeCustomization>
);

export default App;
