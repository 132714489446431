import { useEffect } from 'react';

// types
import { GuardProps } from 'types/auth';
import { useAppNavigate } from 'hooks/navigate/useAppNavigate';
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { goToAuth } = useAppNavigate();
  const { isLoggedIn, isInitialized } = useAuth();

  useEffect(() => {
    if (!isLoggedIn && isInitialized) {
      goToAuth.login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isInitialized]);

  return children;
};

export default AuthGuard;
