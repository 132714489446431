import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { MAIN_ROUTES, RESERVATIONS_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const ReservationsList = Loadable(lazy(() => import('pages/reservations/screens/list/ReservationsList')));
// const ReservationCreate = Loadable(lazy(() => import('pages/reservations/screens/create/ReservationCreate')));
// const ReservationEdit = Loadable(lazy(() => import('pages/reservations/screens/edit/ReservationEdit')));
// const ReservationDetail = Loadable(lazy(() => import('pages/reservations/screens/detail/ReservationDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const ReservationRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.RESERVATIONS,
      children: [
        {
          path: RESERVATIONS_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.RESERVATION_READ]}>
              <ReservationsList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: RESERVATIONS_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.RESERVATION_WRITE]}>
              <MaintenanceUnderConstruction />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: RESERVATIONS_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.RESERVATION_WRITE]}>
              <MaintenanceUnderConstruction />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: RESERVATIONS_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.RESERVATION_READ]}>
              <MaintenanceUnderConstruction />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default ReservationRoutes;
