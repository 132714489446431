import { lazy } from 'react';

import Loadable from 'components/Loadable';
import { MAIN_ROUTES, LOCATIONS_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const LocationsList = Loadable(lazy(() => import('pages/locations/screens/list/LocationsList')));
const LocationCreate = Loadable(lazy(() => import('pages/locations/screens/create/LocationCreate')));
const LocationEdit = Loadable(lazy(() => import('pages/locations/screens/edit/LocationEdit')));
const LocationDetail = Loadable(lazy(() => import('pages/locations/screens/detail/LocationDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const LocationsRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.LOCATIONS,
      children: [
        {
          path: LOCATIONS_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.LOCATION_READ]}>
              <LocationsList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: LOCATIONS_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.LOCATION_WRITE]}>
              <LocationCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: LOCATIONS_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.LOCATION_WRITE]}>
              <LocationEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: LOCATIONS_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.LOCATION_READ]}>
              <LocationDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default LocationsRoutes;
