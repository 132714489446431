// material-ui
import { IdcardOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';
import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';

import usePermission from 'hooks/usePermission';

// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

interface Props {
  disabled?: boolean;
}

const Operator = ({ disabled }: Props) => {
  const { selectedOperator, operatorList, handleOperatorChange } = usePermission();
  const theme = useTheme();

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Select
        disabled={disabled}
        value={selectedOperator._id}
        displayEmpty
        onChange={(event) => handleOperatorChange(event.target.value)}
        autoWidth
        sx={{
          '.MuiSelect-select': {
            py: '4px',
            pl: '4px',
            pr: '2px'
          }
        }}
        renderValue={() => (
          <Stack direction="row" alignItems="center" gap={1}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 0.5,
                backgroundColor: theme.palette.info.lighter,
                py: 0.3,
                px: 0.75,
                borderRadius: 1
              }}
            >
              <IdcardOutlined />
              <Typography>CPO</Typography>
            </Box>
            <Typography>{selectedOperator.companyName}</Typography>
          </Stack>
        )}
      >
        {operatorList.map((operator) => (
          <MenuItem value={operator._id}>{operator.companyName}</MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default Operator;
