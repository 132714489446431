import { Box, CircularProgress } from '@mui/material';

function Loading() {
  return (
    <Box display="flex" flex={1} width="100%" height="480px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
}
export default Loading;
