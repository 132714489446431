import { useAppDispatch } from 'store/ReduxStore';
import { openSnackbar } from 'store/reducers/snackbar';

function useSnackbar() {
  const dispatch = useAppDispatch();

  const openSuccessSnackbar = (message: string, messageValue?: any) => {
    dispatch(
      openSnackbar({
        open: true,
        message,
        messageValue,
        variant: 'alert',
        alert: {
          color: 'success',
          severity: 'success'
        },
        close: true
      })
    );
  };

  const openErrorSnackbar = (message: string, messageValue?: any) => {
    dispatch(
      openSnackbar({
        open: true,
        message,
        messageValue,
        variant: 'alert',
        alert: {
          color: 'error',
          severity: 'error'
        },
        close: true
      })
    );
  };

  return { openSuccessSnackbar, openErrorSnackbar };
}

export { useSnackbar };
