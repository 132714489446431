import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { MAIN_ROUTES, USERS_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const UsersList = Loadable(lazy(() => import('pages/users/screens/list/UsersList')));
const UserCreate = Loadable(lazy(() => import('pages/users/screens/create/UserCreate')));
const UserDetail = Loadable(lazy(() => import('pages/users/screens/detail/UserDetail')));
const UserEdit = Loadable(lazy(() => import('pages/users/screens/edit/UserEdit')));

// ==============================|| MAIN ROUTING ||============================== //

const UsersRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.USERS,
      children: [
        {
          path: USERS_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.USER_READ]}>
              <UsersList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: USERS_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.USER_WRITE]}>
              <UserCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: USERS_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.USER_WRITE]}>
              <UserEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: USERS_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.USER_READ]}>
              <UserDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default UsersRoutes;
