import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { userPool } from 'contexts/CognitoContext';

export const api = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_HTTP_COMMON_BASE_URL,
    prepareHeaders(headers) {
      userPool.getCurrentUser()?.getSession((err: any, ses: CognitoUserSession) => {
        if (err) {
        } else {
          const accesTokenFromSession = ses?.getAccessToken().getJwtToken();
          const idTokenFromSession = ses?.getIdToken().getJwtToken();
          headers.set('Access-Token', `Bearer ${accesTokenFromSession}`);
          headers.set('Id-Token', `Bearer ${idTokenFromSession}`);
        }
      });

      return headers;
    }
  }),
  endpoints: () => ({}),
  tagTypes: ['TariffId', 'Tariffs', 'HardwareId', 'Hardwares', 'Facilities', 'Roles']
});
