import { api } from '../baseApi';
const roleApi = api.injectEndpoints({
  endpoints: (build) => ({
    Update: build.mutation<UpdateRes, UpdateReq>({
      query: (queryArg) => ({ url: `/role/user/${queryArg.userId}`, method: 'PUT', body: queryArg.updateRoleDto })
    }),
    UnassignPresetFromUser: build.mutation<
      UnassignPresetFromUserRes,
      UnassignPresetFromUserReq
    >({
      query: (queryArg) => ({
        url: `/role/user/${queryArg.userId}`,
        method: 'DELETE',
        body: queryArg.unassignPresetFromUserDto
      })
    }),
    AssignPresetToUser: build.mutation<
      AssignPresetToUserRes,
      AssignPresetToUserReq
    >({
      query: (queryArg) => ({ url: `/role/user/${queryArg.userId}/${queryArg.presetId}`, method: 'POST' })
    }),
    GetRolePresetsList: build.query<
      GetRolePresetsListRes,
      GetRolePresetsListReq
    >({
      query: (queryArg) => ({
        url: `/role/presets/list`,
        params: {
          createdAtFrom: queryArg.createdAtFrom,
          createdAtTo: queryArg.createdAtTo,
          size: queryArg.size,
          page: queryArg.page,
          sortKey: queryArg.sortKey,
          sortBy: queryArg.sortBy,
          searchQuery: queryArg.searchQuery
        }
      })
    }),
    GetRolePresets: build.query<GetRolePresetsRes, GetRolePresetsReq>({
      query: () => ({ url: `/role/presets` })
    }),
    CreateRolePreset: build.mutation<
      CreateRolePresetRes,
      CreateRolePresetReq
    >({
      query: (queryArg) => ({ url: `/role/presets`, method: 'POST', body: queryArg.createPresetDto })
    }),
    UpdateRolePreset: build.mutation<
      UpdateRolePresetRes,
      UpdateRolePresetReq
    >({
      query: (queryArg) => ({
        url: `/role/presets/${queryArg.presetId}`,
        method: 'PUT',
        body: queryArg.updatePresetDto
      })
    }),
    DeletePreset: build.mutation<DeletePresetRes, DeletePresetReq>({
      query: (queryArg) => ({ url: `/role/presets/${queryArg.presetId}`, method: 'DELETE' })
    })
  }),
  overrideExisting: false
});
export { roleApi as enhancedApi };
export type UpdateRes = unknown;
export type UpdateReq = {
  userId: string;
  updateRoleDto: UpdateRoleDto;
};
export type UnassignPresetFromUserRes = unknown;
export type UnassignPresetFromUserReq = {
  userId: string;
  unassignPresetFromUserDto: UnassignPresetFromUserDto;
};
export type AssignPresetToUserRes = unknown;
export type AssignPresetToUserReq = {
  userId: string;
  presetId: string;
};
export type GetRolePresetsListRes = /** status 200  */ GetRolePresetsListResponseDto[];
export type GetRolePresetsListReq = {
  createdAtFrom?: string;
  createdAtTo?: string;
  size?: number;
  page?: number;
  sortKey?: string;
  sortBy?: boolean;
  searchQuery?: string;
};
export type GetRolePresetsRes = /** status 200  */ GetRolePresetsResponseDto[];
export type GetRolePresetsReq = void;
export type CreateRolePresetRes = /** status 200  */ object;
export type CreateRolePresetReq = {
  createPresetDto: CreatePresetDto;
};
export type UpdateRolePresetRes = unknown;
export type UpdateRolePresetReq = {
  presetId: string;
  updatePresetDto: UpdatePresetDto;
};
export type DeletePresetRes = unknown;
export type DeletePresetReq = {
  presetId: string;
};
export type UpdateRoleDto = {
  roles: string[];
};
export type UnassignPresetFromUserDto = {
  keepRoles: boolean;
};
export type GetRolePresetsListItemResponseDto = {
  _id: string;
  name: string;
  roles: string[];
  createdAt: string;
  updatedAt: string;
};
export type MetaDto = {
  total: number;
  size: number;
  currentPage: number;
  pages: number;
};
export type GetRolePresetsListResponseDto = {
  rolePresets: GetRolePresetsListItemResponseDto[];
  meta: MetaDto;
};
export type GetRolePresetsResponseDto = {
  _id: string;
  name: string;
  roles: string[];
  createdAt: string;
  updatedAt: string;
};
export type CreatePresetDto = {
  name: string;
  roles: (
    | 'cp_read'
    | 'cp_write'
    | 'connector_type_read'
    | 'connector_type_write'
    | 'charging_profile_read'
    | 'charging_profile_write'
    | 'hardware_read'
    | 'hardware_write'
    | 'charge_operations_read'
    | 'charge_operations_write'
    | 'cpo_read'
    | 'cpo_write'
    | 'customer_read'
    | 'customer_write'
    | 'facility_read'
    | 'facility_write'
    | 'location_read'
    | 'location_write'
    | 'reservation_read'
    | 'reservation_write'
    | 'role_read'
    | 'role_write'
    | 'tariff_read'
    | 'tariff_write'
    | 'billing_read'
    | 'billing_write'
    | 'user_read'
    | 'user_write'
    | 'cp_command_write'
    | 'cp_log_read'
    | 'payment_read'
    | 'payment_write'
    | 'settings_read'
    | 'settings_write'
    | 'rfid_read'
    | 'rfid_write'
    | 'invoice_read'
    | 'invoice_write'
  )[];
};
export type UpdatePresetDto = {
  name: string;
  roles: (
    | 'cp_read'
    | 'cp_write'
    | 'connector_type_read'
    | 'connector_type_write'
    | 'charging_profile_read'
    | 'charging_profile_write'
    | 'hardware_read'
    | 'hardware_write'
    | 'charge_operations_read'
    | 'charge_operations_write'
    | 'cpo_read'
    | 'cpo_write'
    | 'customer_read'
    | 'customer_write'
    | 'facility_read'
    | 'facility_write'
    | 'location_read'
    | 'location_write'
    | 'reservation_read'
    | 'reservation_write'
    | 'role_read'
    | 'role_write'
    | 'tariff_read'
    | 'tariff_write'
    | 'billing_read'
    | 'billing_write'
    | 'user_read'
    | 'user_write'
    | 'cp_command_write'
    | 'cp_log_read'
    | 'payment_read'
    | 'payment_write'
    | 'settings_read'
    | 'settings_write'
    | 'rfid_read'
    | 'rfid_write'
    | 'invoice_read'
    | 'invoice_write'
  )[];
};
export const {
  useUpdateMutation,
  useUnassignPresetFromUserMutation,
  useAssignPresetToUserMutation,
  useGetRolePresetsListQuery,
  useLazyGetRolePresetsListQuery,
  useGetRolePresetsQuery,
  useLazyGetRolePresetsQuery,
  useCreateRolePresetMutation,
  useUpdateRolePresetMutation,
  useDeletePresetMutation
} = roleApi;
