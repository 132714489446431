// material-ui
import { Stack } from '@mui/material';

const Footer = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: '24px 16px 0px', mt: 'auto' }}
  ></Stack>
);

export default Footer;
