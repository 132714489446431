export enum Roles {
  CHARGE_POINT_READ = 'cp_read',
  CHARGE_POINT_WRITE = 'cp_write',
  CONNECTOR_TYPE_READ = 'connector_type_read',
  CONNECTOR_TYPE_WRITE = 'connector_type_write',
  CHARGING_PROFILE_READ = 'charging_profile_read',
  CHARGING_PROFILE_WRITE = 'charging_profile_write',
  HARDWARE_READ = 'hardware_read',
  HARDWARE_WRITE = 'hardware_write',
  CHARGE_OPERATIONS_READ = 'charge_operations_read',
  CHARGE_OPERATIONS_WRITE = 'charge_operations_write',
  CPO_READ = 'cpo_read',
  CPO_WRITE = 'cpo_write',
  CUSTOMER_READ = 'customer_read',
  CUSTOMER_WRITE = 'customer_write',
  FACILITY_READ = 'facility_read',
  FACILITY_WRITE = 'facility_write',
  LOCATION_READ = 'location_read',
  LOCATION_WRITE = 'location_write',
  RESERVATION_READ = 'reservation_read',
  RESERVATION_WRITE = 'reservation_write',
  ROLE_READ = 'role_read',
  ROLE_WRITE = 'role_write',
  TARIFF_READ = 'tariff_read',
  TARIFF_WRITE = 'tariff_write',
  BILLING_READ = 'billing_read',
  BILLING_WRITE = 'billing_write',
  USER_READ = 'user_read',
  USER_WRITE = 'user_write',
  CP_COMMAND_WRITE = 'cp_command_write',
  CP_LOG_READ = 'cp_log_read',
  PAYMENT_READ = 'payment_read',
  PAYMENT_WRITE = 'payment_write',
  SETTINGS_READ = 'settings_read',
  SETTINGS_WRITE = 'settings_write',
  RFID_READ = 'rfid_read',
  RFID_WRITE = 'rfid_write',
  INVOICE_READ = 'invoice_read',
  INVOICE_WRITE = 'invoice_write'
}

export enum RoleAuthEnums {
  write = 'write',
  read = 'read'
}
export type RolePresetsResponse = {
  createdAt: string;
  name: string;
  roles: Array<Roles>;
  updatedAt: string;
  __v: number;
  _id: string;
};

export type CreateRolePresetsReq = {
  name: string;
  roles: Array<Roles>;
};

export type UpdateRolePresetReq = CreateRolePresetsReq & {
  presetId: string;
};

export type DeleteRolePresetReq = {
  presetId: string;
};

export type DeleteUserRoleReq = {
  userId: string;
  keepRoles: boolean;
};

export type UpdateUserRoleReq = {
  userId: string;
  presetId: string;
};
