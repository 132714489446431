import React, { createContext, useEffect, useState } from 'react';

// third-party
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  AuthenticationDetails,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js';

// action - state management
import { setUser, resetUser } from 'store/reducers/auth';

// project imports
import { CognitoContextType, LoginReqTypes } from 'types_/state/auth';
import { setLoading, resetLoading } from 'store/reducers/globalUI';
import { useSnackbar } from 'hooks/common/useSnackbar';
import { useAppDispatch } from 'store/ReduxStore';
import { useAppNavigate } from 'hooks/navigate/useAppNavigate';

// create new user pool
export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as any,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as any
});

// get user
const user = userPool.getCurrentUser();

// create new cognito user with dummy username
var cognitoUser = new CognitoUser({
  Username: 'username',
  Pool: userPool
});

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //

const AWSCognitoContext = createContext<CognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isLoggedIn, setisLoggedIn] = useState<boolean>(false);
  const [operatorId, setOperatorId] = useState<string>('');
  const dispatch = useAppDispatch();
  const { openErrorSnackbar, openSuccessSnackbar } = useSnackbar();
  const { goToAuth } = useAppNavigate();

  const handleSetLoading = () => {
    dispatch(setLoading());
  };

  const handleResetLoading = () => {
    dispatch(resetLoading());
  };

  const handleError = (error: any) => {
    openErrorSnackbar(error.message);
    handleResetLoading();
  };

  const handleLoggedInStates = (user: any) => {
    setisLoggedIn(true);
    dispatch(setUser(user));
    setOperatorId(user.operator_id);
    setIsInitialized(true);
    handleResetLoading();
  };

  const handleLoggedOutStates = () => {
    setisLoggedIn(false);
    dispatch(resetUser());
    setIsInitialized(true);
    handleResetLoading();
  };

  useEffect(() => {
    if (!isInitialized) {
      if (user) {
        handleSetLoading();
        user.getSession((error: null | Error, session: CognitoUserSession) => {
          if (error) {
            handleLoggedOutStates();
          } else if (!session.isValid()) {
            // CognitoUserSession.js => /**
            // * Checks to see if the session is still valid based on session expiry information found
            // * in tokens and the current time (adjusted with clock drift)
            // * @returns {boolean} if the session is still valid

            const refreshToken = session.getRefreshToken();
            refreshSession(refreshToken);
          }
          const userInfo = session?.getIdToken().decodePayload();
          handleLoggedInStates(userInfo);
        });
      } else {
        handleLoggedOutStates();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  const authorize = (session?: CognitoUserSession) => {
    const userInfo = session?.getIdToken().decodePayload();
    openSuccessSnackbar('auth.login.welcome', {
      name: `${userInfo?.given_name} ${userInfo?.family_name}`
    });
    handleLoggedInStates(userInfo);
  };

  const refreshSession = (refreshToken: CognitoRefreshToken) => {
    cognitoUser.refreshSession(refreshToken, (err, session: CognitoUserSession) => {
      if (session) {
        authorize(session);
      } else {
        handleError(err);
      }
    });
  };

  const login = async ({ Username, Password }: LoginReqTypes) => {
    handleSetLoading();
    const authData = new AuthenticationDetails({
      Username,
      Password
    });

    // update cognito user
    cognitoUser = new CognitoUser({
      Username,
      Pool: userPool
    });

    // start login flow
    cognitoUser.authenticateUser(authData, {
      onSuccess: (session: CognitoUserSession) => {
        authorize(session);
      },
      onFailure: (err) => {
        handleError(err);
      },
      newPasswordRequired: () => {
        handleResetLoading();
        goToAuth.changePassword();
      }
    });
  };

  const logout = () => {
    handleSetLoading();
    user?.globalSignOut({
      onSuccess: () => {
        goToAuth.login();
        window.location.reload();
      },
      onFailure: () => {
        cognitoUser.globalSignOut({
          onSuccess: () => {
            goToAuth.login();
            handleLoggedOutStates();
          },
          onFailure: (err) => {
            handleError(err);
          }
        });
      }
    });
  };

  const completeNewPassword = async (newPassword: string) => {
    const requiredAttributes = {}; // deprecated

    cognitoUser?.completeNewPasswordChallenge(newPassword, requiredAttributes, {
      onSuccess(session: CognitoUserSession) {
        authorize(session);
      },
      onFailure(err) {
        handleError(err);
      }
    });
  };
  const changePassword = async (oldPassword: string, newPassword: string) => {
    user?.changePassword(oldPassword, newPassword, function (err, result) {
      if (err) {
        handleError(err);
      } else {
        openSuccessSnackbar('Password Changed');
      }
    });
  };

  return (
    <AWSCognitoContext.Provider
      value={{
        isLoggedIn,
        isInitialized,
        operatorId,
        login,
        logout,
        changePassword,
        completeNewPassword
      }}
    >
      {children}
    </AWSCognitoContext.Provider>
  );
};

export default AWSCognitoContext;
