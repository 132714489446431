import { lazy } from 'react';

import Loadable from 'components/Loadable';
import { MAIN_ROUTES, PROFILE_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';

const UserProfile = Loadable(lazy(() => import('pages/profile/screens/detail/ProfileDetail')));
const UserTabPersonal = Loadable(lazy(() => import('pages/profile/components/detail/TabPersonal')));
const UserTabPassword = Loadable(lazy(() => import('pages/profile/components/detail/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('pages/profile/components/detail/TabSettings')));

// ==============================|| MAIN ROUTING ||============================== //

const ProfileRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.PROFILE,
      element: <UserProfile />,
      children: [
        {
          path: PROFILE_ROUTES.PERSONAL,
          element: <UserTabPersonal />
        },
        {
          path: PROFILE_ROUTES.PASSWORD,
          element: <UserTabPassword />
        },
        {
          path: PROFILE_ROUTES.SETTINGS,
          element: <UserTabSettings />
        }
      ]
    }
  ]
};

export default ProfileRoutes;
