import { api } from '../baseApi';
const facilityApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetFacilities: build.query<
      GetFacilitiesRes,
      GetFacilitiesReq
    >({
      query: () => ({ url: `/facility` })
    }),
    CreateFacility: build.mutation<
      CreateFacilityRes,
      CreateFacilityReq
    >({
      query: (queryArg) => ({ url: `/facility`, method: 'POST', body: queryArg.createFacilityDto })
    }),
    GetFacilityById: build.query<
      GetFacilityByIdRes,
      GetFacilityByIdReq
    >({
      query: (queryArg) => ({ url: `/facility/${queryArg.id}` })
    }),
    UpdateFacility: build.mutation<
      UpdateFacilityRes,
      UpdateFacilityReq
    >({
      query: (queryArg) => ({ url: `/facility/${queryArg.id}`, method: 'PUT', body: queryArg.updateFacilityDto })
    }),
    DeleteFacilityById: build.mutation<
      DeleteFacilityByIdRes,
      DeleteFacilityByIdReq
    >({
      query: (queryArg) => ({ url: `/facility/${queryArg.id}`, method: 'DELETE' })
    })
  }),
  overrideExisting: false
});
export { facilityApi as enhancedApi };
export type GetFacilitiesRes = /** status 200  */ GetFacilityResponseDto[];
export type GetFacilitiesReq = void;
export type CreateFacilityRes = /** status 201  */ object;
export type CreateFacilityReq = {
  createFacilityDto: CreateFacilityDto;
};
export type GetFacilityByIdRes = /** status 200  */ GetFacilityResponseDto;
export type GetFacilityByIdReq = {
  id: any;
};
export type UpdateFacilityRes = unknown;
export type UpdateFacilityReq = {
  id: any;
  updateFacilityDto: UpdateFacilityDto;
};
export type DeleteFacilityByIdRes = unknown;
export type DeleteFacilityByIdReq = {
  id: any;
};
export type GetFacilityResponseMediaDto = {
  _id: string;
  name: string;
  path: string;
};
export type GetFacilityResponseDto = {
  _id: string;
  name: string;
  value: string;
  media: GetFacilityResponseMediaDto;
  createdAt: string;
  updatedAt: string;
};
export type CreateFacilityDto = {
  name: string;
  media: string;
};
export type UpdateFacilityDto = {
  name: string;
  media: string;
};
export const {
  useGetFacilitiesQuery,
  useLazyGetFacilitiesQuery,
  useCreateFacilityMutation,
  useGetFacilityByIdQuery,
  useLazyGetFacilityByIdQuery,
  useUpdateFacilityMutation,
  useDeleteFacilityByIdMutation
} = facilityApi;
