import { useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, Stack, useMediaQuery } from '@mui/material';

// project import
// import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
import Operator from './Operator';
// import Notification from './Notification';
import MobileSection from './MobileSection';
import { useLocation } from 'react-router';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { pathname } = useLocation();

  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const isOperatorHidden = useMemo(() => !pathname.includes('/cpo/'), [pathname]);
  const isOperatorDisabled = useMemo(() => pathname.includes('detail') || pathname.includes('edit'), [pathname]);

  return (
    <Stack flex={1} flexDirection="row" justifyContent="flex-end" alignItems="center" gap={2}>
      {/* {!matchesXs && <Search />} */}
      {!isOperatorHidden && <Operator disabled={isOperatorDisabled} />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </Stack>
  );
};

export default HeaderContent;
