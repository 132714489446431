// types
import { createSlice } from '@reduxjs/toolkit';
import { GlobalUIType } from 'types_/state/globalUI';

// initial state
const initialState: GlobalUIType = {
  isLoading: false
};

// ==============================|| SLICE - MENU ||============================== //

const globalUI = createSlice({
  name: 'globalUI',
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },

    resetLoading(state) {
      state.isLoading = false;
    }
  }
});

export default globalUI.reducer;

export const { setLoading, resetLoading } = globalUI.actions;
