import { api } from '../baseApi';
const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetUsers: build.query<GetUsersRes, GetUsersReq>({
      query: (queryArg) => ({
        url: `/user/${queryArg.cpoId}`,
        params: {
          createdAtFrom: queryArg.createdAtFrom,
          createdAtTo: queryArg.createdAtTo,
          size: queryArg.size,
          page: queryArg.page,
          sortKey: queryArg.sortKey,
          sortBy: queryArg.sortBy,
          searchQuery: queryArg.searchQuery
        }
      })
    }),
    CreateUser: build.mutation<CreateUserRes, CreateUserReq>({
      query: (queryArg) => ({ url: `/user/${queryArg.cpoId}`, method: 'POST', body: queryArg.createCpoUserDto })
    }),
    UserAppInit: build.query<UserAppInitRes, UserAppInitReq>({
      query: (queryArg) => ({ url: `/user/${queryArg.cpoId}/init` })
    }),
    UserGetTableFilters: build.query<
      UserGetTableFiltersRes,
      UserGetTableFiltersReq
    >({
      query: (queryArg) => ({ url: `/user/${queryArg.cpoId}/table`, params: { table: queryArg.table } })
    }),
    UserUpdateTableFilter: build.mutation<
      UserUpdateTableFilterRes,
      UserUpdateTableFilterReq
    >({
      query: (queryArg) => ({
        url: `/user/${queryArg.cpoId}/table`,
        method: 'PUT',
        body: queryArg.updateUserTableFilterDto
      })
    }),
    UserDeleteTableFilters: build.mutation<
      UserDeleteTableFiltersRes,
      UserDeleteTableFiltersReq
    >({
      query: (queryArg) => ({ url: `/user/${queryArg.cpoId}/table/${queryArg.id}`, method: 'DELETE' })
    }),
    ChangeUserLang: build.mutation<ChangeUserLangRes, ChangeUserLangReq>({
      query: (queryArg) => ({ url: `/user/${queryArg.cpoId}/lang`, method: 'POST', body: queryArg.changeUserLangDto })
    }),
    ChangeUserTheme: build.mutation<ChangeUserThemeRes, ChangeUserThemeReq>({
      query: (queryArg) => ({ url: `/user/${queryArg.cpoId}/theme`, method: 'POST', body: queryArg.changeUserThemeDto })
    }),
    GetUserById: build.query<GetUserByIdRes, GetUserByIdReq>({
      query: (queryArg) => ({ url: `/user/${queryArg.cpoId}/${queryArg.id}` })
    }),
    UpdateUser: build.mutation<UpdateUserRes, UpdateUserReq>({
      query: (queryArg) => ({
        url: `/user/${queryArg.cpoId}/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateUserDto
      })
    }),
    DeleteUserById: build.mutation<DeleteUserByIdRes, DeleteUserByIdReq>({
      query: (queryArg) => ({ url: `/user/${queryArg.cpoId}/${queryArg.id}`, method: 'DELETE' })
    })
  }),
  overrideExisting: false
});
export { userApi as enhancedApi };
export type GetUsersRes = /** status 200  */ GetUsersResponseDto;
export type GetUsersReq = {
  cpoId: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  size?: number;
  page?: number;
  sortKey?: string;
  sortBy?: boolean;
  searchQuery?: string;
};
export type CreateUserRes = /** status 201  */ object;
export type CreateUserReq = {
  cpoId: string;
  createCpoUserDto: CreateCpoUserDto;
};
export type UserAppInitRes = /** status 200  */ UserAppInitDto;
export type UserAppInitReq = {
  cpoId: any;
};
export type UserGetTableFiltersRes = /** status 200  */ UserAppInitDto;
export type UserGetTableFiltersReq = {
  table: string;
  cpoId: any;
};
export type UserUpdateTableFilterRes = /** status 200  */ object;
export type UserUpdateTableFilterReq = {
  cpoId: string;
  updateUserTableFilterDto: UpdateUserTableFilterDto;
};
export type UserDeleteTableFiltersRes = /** status 200  */ object;
export type UserDeleteTableFiltersReq = {
  cpoId: string;
  id: string;
};
export type ChangeUserLangRes = /** status 201  */ object;
export type ChangeUserLangReq = {
  cpoId: string;
  changeUserLangDto: ChangeUserLangDto;
};
export type ChangeUserThemeRes = /** status 201  */ object;
export type ChangeUserThemeReq = {
  cpoId: string;
  changeUserThemeDto: ChangeUserThemeDto;
};
export type GetUserByIdRes = /** status 200  */ GetUserResponseDto;
export type GetUserByIdReq = {
  cpoId: string;
  id: string;
};
export type UpdateUserRes = unknown;
export type UpdateUserReq = {
  cpoId: string;
  id: string;
  updateUserDto: UpdateUserDto;
};
export type DeleteUserByIdRes = unknown;
export type DeleteUserByIdReq = {
  cpoId: string;
  id: string;
};
export type GetUsersResponseCpodto = {
  _id: string;
  group: string;
  companyName: string;
};
export type GetUsersResponseRolePresetDto = {
  _id: string;
  name: string;
};
export type GetUsersResponseUserDto = {
  _id: string;
  email: string;
  phone: string;
  name: string;
  surname: string;
  operator: GetUsersResponseCpodto;
  roles: string[];
  rolePreset: GetUsersResponseRolePresetDto;
  status: 'Active' | 'Hold' | 'Deleted';
  createdAt: string;
  updatedAt: string;
};
export type MetaDto = {
  total: number;
  size: number;
  currentPage: number;
  pages: number;
};
export type GetUsersResponseDto = {
  users: GetUsersResponseUserDto[];
  meta: MetaDto;
};
export type CreateCpoUserDto = {
  email: string;
  phone: string;
  name: string;
  surname: string;
  presetId: string;
};
export type UserAppInitCposDto = {
  _id: string;
  group: string;
  companyName: string;
};
export type UserAppInitUserDto = {
  name: string;
  surname: string;
  phone: string;
  email: string;
  lang: string;
  theme: string;
};
export type UserAppInitPresetsDto = {
  name: string;
  roles: string[];
};
export type UserAppInitDto = {
  cpoList?: UserAppInitCposDto[];
  user: UserAppInitUserDto;
  preset: UserAppInitPresetsDto;
  tableFilters: object[];
};
export type UpdateUserTableFilterDto = {
  table: string;
  name: string;
  value?: object;
};
export type ChangeUserLangDto = {
  lang: string;
};
export type ChangeUserThemeDto = {
  theme: string;
};
export type GetUserResponseCpodto = {
  _id: string;
  group: string;
  companyName: string;
};
export type GetUserResponseRolePresetDto = {
  _id: string;
  name: string;
};
export type GetUserResponseDto = {
  _id: string;
  email: string;
  phone: string;
  name: string;
  surname: string;
  operator: GetUserResponseCpodto;
  roles: string[];
  rolePreset: GetUserResponseRolePresetDto;
  status: 'Active' | 'Hold' | 'Deleted';
  createdAt: string;
  updatedAt: string;
};
export type UpdateUserDto = {
  email: string;
  phone: string;
  name: string;
  surname: string;
  presetId: string;
};
export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useCreateUserMutation,
  useUserAppInitQuery,
  useLazyUserAppInitQuery,
  useUserGetTableFiltersQuery,
  useLazyUserGetTableFiltersQuery,
  useUserUpdateTableFilterMutation,
  useUserDeleteTableFiltersMutation,
  useChangeUserLangMutation,
  useChangeUserThemeMutation,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useUpdateUserMutation,
  useDeleteUserByIdMutation
} = userApi;
