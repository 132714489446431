export const MAIN_ROUTES = {
  DASHBOARD: '/cpo/:cpoId/dashboard',
  CHARGE_POINTS: '/cpo/:cpoId/charge-points',
  CHARGE_OPERATIONS: '/cpo/:cpoId/charge-operations',
  LOCATIONS: '/cpo/:cpoId/locations',
  RESERVATIONS: '/cpo/:cpoId/reservations',
  TARIFFS: '/cpo/:cpoId/tariffs',
  HARDWARES: '/cms/hardwares',
  CONNECTORS: '/cms/connectors',
  FACILITIES: '/cms/facilities',
  CPOS: '/e-msp/cpos',
  USERS: '/cpo/:cpoId/users',
  ROLES: '/e-msp/roles',
  CUSTOMERS: '/e-msp/customers',
  EPDK: '/epdk',
  SETTINGS: '/settings',
  RFID: '/e-msp/rfid',
  BILLS: '/e-msp/bills',
  PROFILE: '/profile'
};

export const AUTH_ROUTES = {
  LOGIN: 'login',
  REGISTER: 'register',
  CHANGE_PASSWORD: 'change-password',
  FORGOT_PASSWORD: 'forgot-password',
  CHECK_MAIL: 'check-mail',
  RESET_PASSWORD: 'reset-password',
  CODE_VERIFICATION: 'code-verification'
};
export const CHARGE_POINTS_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: 'detail/:id',
  EDIT: ':id/edit',
  PIT_STOP: 'detail/:id/pit-stop/'
};
export const CHARGE_OPERATIONS_ROUTES = {
  LIST: 'list',
  DETAIL: 'detail/:id'
};
export const LOCATIONS_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: 'detail/:id',
  EDIT: ':id/edit'
};
export const TARIFFS_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: 'detail/:id',
  EDIT: ':id/edit'
};
export const RESERVATIONS_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: ':id/detail',
  EDIT: 'id/edit'
};
export const CPO_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: ':id/detail',
  EDIT: ':id/edit'
};
export const USERS_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: ':id/detail',
  EDIT: ':id/edit'
};
export const ROLES_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: ':id/detail',
  EDIT: ':id/edit'
};
export const HARDWARE_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: ':id/detail',
  EDIT: ':id/edit'
};
export const CONNECTOR_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: ':id/detail',
  EDIT: ':id/edit'
};
export const FACILITIES_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: ':id/detail',
  EDIT: ':id/edit'
};
export const CUSTOMERS_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: ':id/detail',
  EDIT: ':id/edit'
};

export const EPDK_ROUTES = {
  CP_SETTINGS: 'cp-settings',
  LOGS: 'logs'
};

export const SETTINGS_ROUTES = {
  GENERAL: 'general',
  PAYMENT: 'payment',
  EPDK: 'epdk',
  ACCOUNTING: 'accounting'
};

export const RFID_ROUTES = {
  LIST: 'list',
  CREATE: 'create',
  DETAIL: ':id/detail'
};

export const BILL_ROUTES = {
  LIST: 'list',
  DETAIL: 'detail/:id'
};

export const PROFILE_ROUTES = {
  PERSONAL: 'personal',
  PASSWORD: 'password',
  SETTINGS: 'settings'
};
