import { api } from '../baseApi';
const connectorTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetConnectorTypes: build.query<
      GetConnectorTypesRes,
      GetConnectorTypesReq
    >({
      query: () => ({ url: `/connector-type` })
    }),
    CreateConnectorType: build.mutation<
      CreateConnectorTypeRes,
      CreateConnectorTypeReq
    >({
      query: (queryArg) => ({ url: `/connector-type`, method: 'POST', body: queryArg.createConnectorTypeDto })
    }),
    GetConnectorTypeById: build.query<
      GetConnectorTypeByIdRes,
      GetConnectorTypeByIdReq
    >({
      query: (queryArg) => ({ url: `/connector-type/${queryArg.id}` })
    }),
    UpdateConnectorType: build.mutation<
      UpdateConnectorTypeRes,
      UpdateConnectorTypeReq
    >({
      query: (queryArg) => ({
        url: `/connector-type/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateConnectorTypeDto
      })
    }),
    DeleteConnectorTypeById: build.mutation<
      DeleteConnectorTypeByIdRes,
      DeleteConnectorTypeByIdReq
    >({
      query: (queryArg) => ({ url: `/connector-type/${queryArg.id}`, method: 'DELETE' })
    })
  }),
  overrideExisting: false
});
export { connectorTypeApi as enhancedApi };
export type GetConnectorTypesRes = /** status 200  */ GetConnectorTypesResponseDto[];
export type GetConnectorTypesReq = void;
export type CreateConnectorTypeRes = /** status 201  */ object;
export type CreateConnectorTypeReq = {
  createConnectorTypeDto: CreateConnectorTypeDto;
};
export type GetConnectorTypeByIdRes = /** status 200  */ GetConnectorTypesResponseDto;
export type GetConnectorTypeByIdReq = {
  id: any;
};
export type UpdateConnectorTypeRes = unknown;
export type UpdateConnectorTypeReq = {
  id: any;
  updateConnectorTypeDto: UpdateConnectorTypeDto;
};
export type DeleteConnectorTypeByIdRes = unknown;
export type DeleteConnectorTypeByIdReq = {
  id: any;
};
export type GetConnectorTypesResponseMediaDto = {
  _id: string;
  name: string;
  path: string;
};
export type GetConnectorTypesResponseDto = {
  connectorName: string;
  connectorType: string;
  connectorPowerType: 'AC' | 'DC';
  maxPower: number;
  media: GetConnectorTypesResponseMediaDto;
  createdAt: string;
  updated: string;
};
export type CreateConnectorTypeDto = {
  connectorName: string;
  connectorType: string;
  connectorPowerType: 'AC' | 'DC';
  maxPower: number;
  media: string;
};
export type UpdateConnectorTypeDto = {
  connectorName: string;
  connectorType: string;
  connectorPowerType: 'AC' | 'DC';
  maxPower: number;
  media: string;
};
export const {
  useGetConnectorTypesQuery,
  useLazyGetConnectorTypesQuery,
  useCreateConnectorTypeMutation,
  useGetConnectorTypeByIdQuery,
  useLazyGetConnectorTypeByIdQuery,
  useUpdateConnectorTypeMutation,
  useDeleteConnectorTypeByIdMutation
} = connectorTypeApi;
