import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const Settings = Loadable(lazy(() => import('pages/settings/screens/list/settings')));
const TabGeneral = Loadable(lazy(() => import('components/settings/TabGeneral')));
const TabPayment = Loadable(lazy(() => import('components/settings/TabPayment')));
const TabEPDK = Loadable(lazy(() => import('components/settings/TabEPDK')));
const TabAccounting = Loadable(lazy(() => import('components/settings/TabAccounting')));

// ==============================|| MAIN ROUTING ||============================== //

const SettingsRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <RoleBasedRouteGuard accessibleRoles={[Roles.SETTINGS_WRITE]}>
          <MainLayout />
        </RoleBasedRouteGuard>
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.SETTINGS,
      element: <Settings />,
      children: [
        {
          path: 'general',
          element: <TabGeneral />
        },
        {
          path: 'payment',
          element: <TabPayment />
        },
        {
          path: 'epdk',
          element: <TabEPDK />
        },
        {
          path: 'accounting',
          element: <TabAccounting />
        }
      ]
    }
  ]
};

export default SettingsRoutes;
