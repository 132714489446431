// ==============================|| OVERRIDES - Tooltip ||============================== //

import { Theme } from '@mui/material/styles';
import { ThemeMode } from 'types/config';

export default function Tooltip(theme: Theme, mode: ThemeMode) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: { color: mode === 'dark' ? theme.palette.common.black : theme.palette.common.white }
      }
    }
  };
}
