import { ReactNode, useEffect, useState } from 'react';

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';

// project import
import useConfig from 'hooks/useConfig';
import { I18n } from 'types/config';

// load locales files
const loadLocaleData = (locale: I18n) => {
  switch (locale) {
    case 'tr':
      return import('utils/locales/tr.json');
    case 'en':
    default:
      return import('utils/locales/en.json');
  }
};

// ==============================|| LOCALIZATION ||============================== //

interface Props {
  children: ReactNode;
}

const Locales = ({ children }: Props) => {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState<
    Record<string, string> | Record<string, MessageFormatElement[]> | undefined
  >();

  useEffect(() => {
    loadLocaleData(i18n).then(
      (d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
        setMessages(d.default);
      }
    );
  }, [i18n]);

  // const flattenMessages = (nestedMessages: any, prefix = '') => {
  //   if (nestedMessages === null) {
  //     return {};
  //   }
  //   return Object.keys(nestedMessages).reduce((messages, key) => {
  //     const value = nestedMessages[key];
  //     const prefixedKey = prefix ? `${prefix}.${key}` : key;

  //     if (typeof value === 'string') {
  //       Object.assign(messages, { [prefixedKey]: value });
  //     } else {
  //       Object.assign(messages, flattenMessages(value, prefixedKey));
  //     }

  //     return messages;
  //   }, {});
  // };

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="tr" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default Locales;
