import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userPool } from 'contexts/CognitoContext';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const uploadAPI = createApi({
  reducerPath: 'uploadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_UPLOAD_BASE_URL,
    prepareHeaders(headers, api) {
      userPool.getCurrentUser()?.getSession((err: any, ses: CognitoUserSession) => {
        if (err) {
        } else {
          const accesTokenFromSession = ses?.getAccessToken().getJwtToken();
          const idTokenFromSession = ses?.getIdToken().getJwtToken();
          headers.set('Access-Token', `Bearer ${accesTokenFromSession}`);
          headers.set('Id-Token', `Bearer ${idTokenFromSession}`);
        }
      });

      return headers;
    }
  }),
  endpoints: (builder) => ({
    uploadMultipleImage: builder.mutation<UploadMediaResponseDto, FormData>({
      query(data) {
        return {
          url: '/media',
          method: 'POST',
          body: data,
          formData: true
        };
      }
    })
  })
});

export const { useUploadMultipleImageMutation } = uploadAPI;

export type MediaVariations = {
  thumbnail: string;
  small: string;
  medium: string;
  large: string;
};

export type UploadMediaResponseDto = {
  _id: string;
  name: string;
  path: string;
  variations?: MediaVariations;
  mimeType: string;
  createdAt: string;
  updatedAt: string;
};
