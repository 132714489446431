import { FormattedMessage } from 'react-intl';
import {
  IdcardOutlined,
  SolutionOutlined,
  CreditCardOutlined,
  UsergroupAddOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { NavItemType } from 'types_/state/menu';
import { Roles } from 'types/roles';

const eMsp: NavItemType = {
  id: 'emsp',
  title: <FormattedMessage id="emsp-group-title" />,
  type: 'group',
  rootOnly: true,
  children: [
    {
      id: 'cpos',
      title: <FormattedMessage id="cpos-page-child-title" />,
      url: '/e-msp/cpos/list',
      type: 'item',
      icon: IdcardOutlined,
      accessibleRoles: [Roles.CPO_READ]
    },
    {
      id: 'roles',
      title: <FormattedMessage id="roles-page-child-title" />,
      type: 'item',
      url: '/e-msp/roles/list',
      icon: SolutionOutlined,
      accessibleRoles: [Roles.ROLE_READ]
    },
    // {
    //   id: 'vehicles',
    //   title: <FormattedMessage id="vehicles-page-child-title" />,
    //   type: 'item',
    //   url: '/vehicles/list',
    //   icon: CarOutlined
    // },
    // {
    //   id: 'charge-details',
    //   title: <FormattedMessage id='charge-details-page-child-title' />,
    //   type: 'item',
    //   url: '/charge-details/list',
    //   icon: DatabaseOutlined,
    // },
    {
      id: 'customers',
      title: <FormattedMessage id="customers-page-child-title" />,
      type: 'item',
      url: '/e-msp/customers/list',
      icon: UsergroupAddOutlined,
      accessibleRoles: [Roles.CUSTOMER_READ]
    },
    {
      id: 'rfid',
      title: <FormattedMessage id="rfids-page-child-title" />,
      type: 'item',
      url: '/e-msp/rfid/list',
      icon: CreditCardOutlined,
      accessibleRoles: [Roles.RFID_READ]
    },
    {
      id: 'bills',
      title: <FormattedMessage id="bills-page-child-title" />,
      type: 'item',
      url: '/e-msp/bills/list',
      icon: FileTextOutlined,
      accessibleRoles: [Roles.BILLING_READ]
    }
  ]
};

export default eMsp;
