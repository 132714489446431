import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';

const DashboardMainPage = Loadable(lazy(() => import('pages/dashboard/screens/Dashboard')));

// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.DASHBOARD,
      element: <DashboardMainPage />
    }
  ]
};

export default DashboardRoutes;
