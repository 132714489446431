import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import config from 'config';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const logoSwitch = (name: string | undefined, reverse?: boolean) => {
  switch (name) {
    case 'minus':
      return <img src="/minus.png" style={{ width: 100 }} />;
    case 'porty4car':
      return <img src="/porty4car.png" style={{ width: 100, height: 50, objectFit: 'cover' }} />;
    case 'ecomobil':
      return <img src="/ecomobil.png" style={{ width: 100, objectFit: 'cover' }} />;
    default:
      return <Logo reverse={reverse} />;
  }
};

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => (
  <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
    {isIcon ? <LogoIcon /> : logoSwitch(process.env.REACT_APP_NAME, reverse)}
  </ButtonBase>
);

export default LogoSection;
