import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { BILL_ROUTES, MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const BillList = Loadable(lazy(() => import('pages/bill/screens/list/BillsList')));
const BillDetail = Loadable(lazy(() => import('pages/bill/screens/detail/BillDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const BillRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <MainLayout />
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.BILLS,
      children: [
        {
          path: BILL_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.BILLING_READ]}>
              <BillList />
            </RoleBasedRouteGuard>
          )
        },

        {
          path: BILL_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.BILLING_READ]}>
              <BillDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default BillRoutes;
