import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { resetLoading } from 'store/reducers/globalUI';
import { openSnackbar } from 'store/reducers/snackbar';
import { errorToastKeys, showOnlyErrorToastEndpoints, showToastEndpoints } from './ActionConstants';

export const ErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    api.dispatch(resetLoading());
    const actionName = action.meta.arg.endpointName;
    const isActionHasErrorToast =
      showToastEndpoints.some((endpoint) => endpoint === actionName) ||
      showOnlyErrorToastEndpoints.some((endpoint) => endpoint === actionName);

    if (isActionHasErrorToast) {
      api.dispatch(
        openSnackbar({
          open: true,
          message: errorToastKeys[actionName],
          variant: 'alert',
          alert: {
            color: 'error',
            severity: 'error'
          },
          close: true
        })
      );
    }
  }

  return next(action);
};
