import { api } from '../baseApi';
const cpoApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCpOs: build.query<GetCpOsRes, GetCpOsReq>({
      query: (queryArg) => ({
        url: `/cpo`,
        params: {
          createdAtFrom: queryArg.createdAtFrom,
          createdAtTo: queryArg.createdAtTo,
          size: queryArg.size,
          page: queryArg.page,
          sortKey: queryArg.sortKey,
          sortBy: queryArg.sortBy,
          searchQuery: queryArg.searchQuery
        }
      })
    }),
    CreateCpo: build.mutation<CreateCpoRes, CreateCpoReq>({
      query: (queryArg) => ({ url: `/cpo`, method: 'POST', body: queryArg.createCpoDto })
    }),
    GetCpoById: build.query<GetCpoByIdRes, GetCpoByIdReq>({
      query: (queryArg) => ({ url: `/cpo/${queryArg.cpoId}` })
    })
  }),
  overrideExisting: false
});
export { cpoApi as enhancedApi };
export type GetCpOsRes = /** status 200  */ GetCpOsResponseDto;
export type GetCpOsReq = {
  createdAtFrom?: string;
  createdAtTo?: string;
  size?: number;
  page?: number;
  sortKey?: string;
  sortBy?: boolean;
  searchQuery?: string;
};
export type CreateCpoRes = /** status 201  */ object;
export type CreateCpoReq = {
  createCpoDto: CreateCpoDto;
};
export type GetCpoByIdRes = /** status 200  */ GetCpoResponseDto;
export type GetCpoByIdReq = {
  cpoId: string;
};
export type GetCpoResponseDto = {
  _id: string;
  companyName: string;
  group: string;
  createdAt: string;
  updatedAt: string;
};
export type MetaDto = {
  total: number;
  size: number;
  currentPage: number;
  pages: number;
};
export type GetCpOsResponseDto = {
  cpos: GetCpoResponseDto[];
  meta: MetaDto;
};
export type CreateCpoDto = {
  companyName: string;
};
export const {
  useGetCpOsQuery,
  useLazyGetCpOsQuery,
  useCreateCpoMutation,
  useGetCpoByIdQuery,
  useLazyGetCpoByIdQuery
} = cpoApi;
