import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { CHARGE_OPERATIONS_ROUTES, MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { Roles } from 'types/roles';
import { RoleBasedRouteGuard } from 'utils/route-guard/RoleBasedGuard';

const ChargeOperationDetails = Loadable(
  lazy(() => import('pages/charge-operations/screens/details/ChargeOperationsDetails'))
);
const ChargeOperationsListPage = Loadable(
  lazy(() => import('pages/charge-operations/screens/list/ChargeOperationsList'))
);

// ==============================|| MAIN ROUTING ||============================== //

const ChargeOperationsRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.CHARGE_OPERATIONS,
      children: [
        {
          path: CHARGE_OPERATIONS_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CHARGE_OPERATIONS_READ]}>
              <ChargeOperationsListPage />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CHARGE_OPERATIONS_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CHARGE_OPERATIONS_READ]}>
              <ChargeOperationDetails />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default ChargeOperationsRoutes;
