import { CpoType } from 'types_/state/permission';

export function replaceCurrentOperatorParam(operator: CpoType | undefined, url: string) {
  if (operator && url.indexOf('/cpo/') > -1) {
    const routeParts = url.split('/');

    routeParts.splice(2, 1, operator._id);

    return routeParts.join('/');
  }

  return url;
}
