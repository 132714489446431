import { FormattedMessage } from 'react-intl';
import { HomeOutlined } from '@ant-design/icons';
import { NavItemType } from 'types_/state/menu';

const dashboard: NavItemType = {
  id: 'dashboard',
  title: <FormattedMessage id="dashboard-group-title" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard-page-child-title" />,
      url: '/dashboard',
      type: 'item',
      icon: HomeOutlined,
      withCpoId: true
    }
  ]
};

export default dashboard;
