import { FormattedMessage } from 'react-intl';
import { /* DatabaseOutlined, */ DeliveredProcedureOutlined } from '@ant-design/icons';
import { NavItemType } from 'types_/state/menu';
import { MAIN_ROUTES, EPDK_ROUTES } from 'types/routes';
import { Roles } from 'types/roles';

const epdk: NavItemType = {
  id: 'epdk',
  title: <FormattedMessage id="epdk-group-title" />,
  type: 'group',
  rootOnly: true,
  children: [
    {
      id: 'cp-settings',
      title: <FormattedMessage id="epdk-cp-settings-page-child-title" />,
      type: 'item',
      url: `${MAIN_ROUTES.EPDK}/${EPDK_ROUTES.CP_SETTINGS}`,
      icon: DeliveredProcedureOutlined,
      accessibleRoles: [Roles.CP_LOG_READ]
    }
    /*   {
      id: 'logs',
      title: <FormattedMessage id="epdk-logs-page-child-title" />,
      type: 'item',
      url: `${MAIN_ROUTES.EPDK}/${EPDK_ROUTES.LOGS}`,
      icon: DatabaseOutlined,
      accessibleRoles: [Roles.CP_LOG_READ]
    } */
  ]
};

export default epdk;
