import { useContext } from 'react';

// permission provider
import PermissionContext from 'contexts/PermissionContext';

// ==============================|| AUTH HOOKS ||============================== //

const usePermission = () => {
  const context = useContext(PermissionContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default usePermission;
