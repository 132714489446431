// third-party
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

// project import
import menu from './reducers/menu';
import snackbar from './reducers/snackbar';
import globalUI from './reducers/globalUI';
import auth from './reducers/auth';
import { api } from './api/baseApi';
import { uploadAPI } from './api/uploadApi';

import { LoadingMiddleware } from '../middleware/LoadingMiddleware';
import { SuccessMiddleware } from '../middleware/SuccessMiddleware';
import { ErrorMiddleware } from '../middleware/ErrorMiddleware';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const reducer = combineReducers({
  auth,
  menu,
  snackbar,
  globalUI,
  [api.reducerPath]: api.reducer,
  [uploadAPI.reducerPath]: uploadAPI.reducer
});

const appStore = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      api.middleware,
      uploadAPI.middleware,
      LoadingMiddleware,
      SuccessMiddleware,
      ErrorMiddleware
    ]),
  devTools: true
});

export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { appStore };
