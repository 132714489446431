import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { MAIN_ROUTES, TARIFFS_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const TariffsList = Loadable(lazy(() => import('pages/tariffs/screens/list/TariffsList')));
const TariffDetail = Loadable(lazy(() => import('pages/tariffs/screens/detail/TariffDetail')));
const TariffCreate = Loadable(lazy(() => import('pages/tariffs/screens/create/TariffCreate')));
const TariffEdit = Loadable(lazy(() => import('pages/tariffs/screens/edit/TariffEdit')));

// ==============================|| MAIN ROUTING ||============================== //

const TariffsRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.TARIFFS,
      children: [
        {
          path: TARIFFS_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.TARIFF_READ]}>
              <TariffsList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: TARIFFS_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.TARIFF_WRITE]}>
              <TariffCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: TARIFFS_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.TARIFF_WRITE]}>
              <TariffEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: TARIFFS_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.TARIFF_READ]}>
              <TariffDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default TariffsRoutes;
