import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { CUSTOMERS_ROUTES, MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const CustomersList = Loadable(lazy(() => import('pages/customers/screens/list/CustomersList')));
const CustomerCreate = Loadable(lazy(() => import('pages/customers/screens/create/CustomerCreate')));
const CustomerEdit = Loadable(lazy(() => import('pages/customers/screens/edit/CustomerEdit')));
const CustomerDetail = Loadable(lazy(() => import('pages/customers/screens/detail/CustomerDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const CustomerRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <MainLayout />
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.CUSTOMERS,
      children: [
        {
          path: CUSTOMERS_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CUSTOMER_READ]}>
              <CustomersList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CUSTOMERS_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CUSTOMER_WRITE]}>
              <CustomerCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CUSTOMERS_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CUSTOMER_WRITE]}>
              <CustomerEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CUSTOMERS_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CUSTOMER_READ]}>
              <CustomerDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default CustomerRoutes;
