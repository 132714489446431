import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { FACILITIES_ROUTES, MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const FacilitiesList = Loadable(lazy(() => import('pages/facilities/screens/list/FacilitiesList')));
const FacilityCreate = Loadable(lazy(() => import('pages/facilities/screens/create/FacilityCreate')));
const FacilityEdit = Loadable(lazy(() => import('pages/facilities/screens/edit/FacilityEdit')));
const FacilityDetail = Loadable(lazy(() => import('pages/facilities/screens/detail/FacilityDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const FacilitiesRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <MainLayout />
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.FACILITIES,
      children: [
        {
          path: FACILITIES_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.FACILITY_READ]}>
              <FacilitiesList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: FACILITIES_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.FACILITY_WRITE]}>
              <FacilityCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: FACILITIES_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.FACILITY_WRITE]}>
              <FacilityEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: FACILITIES_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.FACILITY_READ]}>
              <FacilityDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default FacilitiesRoutes;
