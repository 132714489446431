import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { MAIN_ROUTES, RFID_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const RfidList = Loadable(lazy(() => import('pages/rfid/screens/list/RfidList')));
const RfidDetail = Loadable(lazy(() => import('pages/rfid/screens/detail/RfidDetail')));
const RfidCreate = Loadable(lazy(() => import('pages/rfid/screens/create/RfidCreate')));
// ==============================|| MAIN ROUTING ||============================== //

const RfidRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <MainLayout />
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.RFID,
      children: [
        {
          path: RFID_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.RFID_READ]}>
              <RfidList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: RFID_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.RFID_READ]}>
              <RfidDetail />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: RFID_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.RFID_WRITE]}>
              <RfidCreate />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default RfidRoutes;
