// import { enhancedApi as ChargePointApi } from '../store/api/generated/charge-point-api';
// import { enhancedApi as LocationsApi } from '../store/api/generated/locations-api';
// import { enhancedApi as ChargeOperationsApi } from '../store/api/generated/charge-operations-api';
// import { enhancedApi as TariffApi } from '../store/api/generated/tariff-api';
// import { enhancedApi as ReservationApi } from '../store/api/generated/reservation-api';
import { enhancedApi as CpoApi } from '../store/api/generated/cpo-api';
import { enhancedApi as UserApi } from '../store/api/generated/user-api';
import { enhancedApi as RoleApi } from '../store/api/generated/role-api';
// import { enhancedApi as CustomerApi } from '../store/api/generated/customer-api';
// import { enhancedApi as HardwareApi } from '../store/api/generated/hardware-api';
import { enhancedApi as ConnectorTypeApi } from '../store/api/generated/connector-type-api';
import { enhancedApi as FacilityApi } from '../store/api/generated/facility-api';
// import { enhancedApi as BillingApi } from '../store/api/generated/billing-api';
// import { enhancedApi as CustomerBridgeApi } from '../store/api/generated/customer-bridge-api';
// import { enhancedApi as DashboardApi } from '../store/api/generated/dashboard-api';
import { enhancedApi as Occp16Api } from '../store/api/generated/ocpp-16-api';
// import { enhancedApi as PaymentApi } from '../store/api/generated/payment-api';
// import { enhancedApi as RfidApi } from '../store/api/generated/rfid-api';
// import { enhancedApi as SettingsApi } from '../store/api/generated/settings-api';

export const showToastEndpoints = [
  FacilityApi.endpoints.CreateFacility.name,
  FacilityApi.endpoints.DeleteFacilityById.name,
  ConnectorTypeApi.endpoints.CreateConnectorType.name,
  ConnectorTypeApi.endpoints.DeleteConnectorTypeById.name,
  ConnectorTypeApi.endpoints.UpdateConnectorType.name,
  RoleApi.endpoints.CreateRolePreset.name,
  RoleApi.endpoints.DeletePreset.name,
  RoleApi.endpoints.UpdateRolePreset.name,
  UserApi.endpoints.CreateUser.name,
  UserApi.endpoints.DeleteUserById.name,
  UserApi.endpoints.UpdateUser.name,
  CpoApi.endpoints.CreateCpo.name,
  Occp16Api.endpoints.Reset.name
];

export const showOnlySuccessToastEndpoints = [];
export const showOnlyErrorToastEndpoints = [];

export const silentCallEndpoints = [
  'GetAllLocationsCombo',
  'GetChargePointsCombo',
  'GetChargeOperations',
  'GetTariffs',
  'GetChargePoints',
  'GetLocations',
  'GetUsers',
  'GetReservations',
  'GetCpOs',
  'GetRolePresetsList',
  'GetCustomers',
  'GetRfidCards',
  'GetHardwares',
  'GetLogs',
  'GetChargePointMeterValuesByChargePointId',
  'GetChargePointMeterGraphs',
  'GetChargePointById',
  'GetBills'
];

export const successToastKeys = {
  [FacilityApi.endpoints.CreateFacility.name]: 'facility.create.success',
  [FacilityApi.endpoints.DeleteFacilityById.name]: 'facility.delete.success',
  [ConnectorTypeApi.endpoints.CreateConnectorType.name]: 'connector.create.success',
  [ConnectorTypeApi.endpoints.DeleteConnectorTypeById.name]: 'connector.delete.success',
  [ConnectorTypeApi.endpoints.UpdateConnectorType.name]: 'connector.update.success',
  [RoleApi.endpoints.CreateRolePreset.name]: 'roles.create.success',
  [RoleApi.endpoints.DeletePreset.name]: 'roles.delete.success',
  [RoleApi.endpoints.UpdateRolePreset.name]: 'roles.update.success',
  [UserApi.endpoints.CreateUser.name]: 'user.create.success',
  [UserApi.endpoints.UpdateUser.name]: 'user.update.success',
  [UserApi.endpoints.DeleteUserById.name]: 'user.delete.success',
  [CpoApi.endpoints.CreateCpo.name]: 'cpo.create.success',
  [Occp16Api.endpoints.Reset.name]: 'ocpp16.reset.success'
};
export const errorToastKeys = {
  [FacilityApi.endpoints.CreateFacility.name]: 'facility.create.error',
  [FacilityApi.endpoints.DeleteFacilityById.name]: 'facility.delete.error',
  [ConnectorTypeApi.endpoints.CreateConnectorType.name]: 'connector.create.error',
  [ConnectorTypeApi.endpoints.DeleteConnectorTypeById.name]: 'connector.delete.error',
  [ConnectorTypeApi.endpoints.UpdateConnectorType.name]: 'connector.update.error',
  [RoleApi.endpoints.CreateRolePreset.name]: 'roles.create.error',
  [RoleApi.endpoints.DeletePreset.name]: 'roles.delete.error',
  [RoleApi.endpoints.UpdateRolePreset.name]: 'roles.update.error',
  [UserApi.endpoints.CreateUser.name]: 'user.create.error',
  [UserApi.endpoints.UpdateUser.name]: 'user.update.error',
  [UserApi.endpoints.DeleteUserById.name]: 'user.delete.error',
  [CpoApi.endpoints.CreateCpo.name]: 'cpo.create.error',
  [Occp16Api.endpoints.Reset.name]: 'ocpp16.reset.error'
};
