import { FormattedMessage } from 'react-intl';
import { ApiOutlined, RobotOutlined, ShopOutlined } from '@ant-design/icons';
import { NavItemType } from 'types_/state/menu';
import { Roles } from 'types/roles';

const content: NavItemType = {
  id: 'content',
  title: <FormattedMessage id="content" />,
  type: 'group',
  rootOnly: true,
  children: [
    {
      id: 'hardwares',
      title: <FormattedMessage id="hardwares-page-child-title" />,
      type: 'item',
      url: '/cms/hardwares/list',
      icon: RobotOutlined,
      accessibleRoles: [Roles.HARDWARE_READ]
    },
    {
      id: 'connectors',
      title: <FormattedMessage id="connectors-page-child-title" />,
      type: 'item',
      url: '/cms/connectors/list',
      icon: ApiOutlined,
      accessibleRoles: [Roles.CONNECTOR_TYPE_READ]
    },
    {
      id: 'facilities',
      title: <FormattedMessage id="facilities-page-child-title" />,
      type: 'item',
      url: '/cms/facilities/list',
      icon: ShopOutlined,
      accessibleRoles: [Roles.FACILITY_READ]
    }
  ]
};

export default content;
