// types
import { createSlice } from '@reduxjs/toolkit';
import { AuthProps } from 'types_/state/auth';

// initial state
export const initialState: AuthProps = {
  user: null
};

// ==============================|| SLICE - MENU ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    resetUser(state) {
      state = initialState;
    }
  }
});

export default auth.reducer;

export const { setUser, resetUser } = auth.actions;
