/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 270 120"
      width={90}
      height={90}
    >
      <path
        d="M90.88,27.4l17.5,72.06c.88,3.42,1.31,5.81,1.31,7.19,0,3.67-2.15,5.5-6.44,5.5-1.75,0-3.07-.39-3.97-1.16-.9-.77-1.53-1.71-1.91-2.81-.38-1.1-.92-3.11-1.62-6.03l-17.44-72.06c-.83-3.58-1.25-5.94-1.25-7.06,0-1.75.55-3.12,1.66-4.12,1.1-1,2.68-1.5,4.72-1.5,2.46,0,4.08.71,4.88,2.12.79,1.42,1.65,4.04,2.56,7.88Z"
        fill="#64808e"
        stroke-width="0"
      />
      <path
        d="M126.82,27.4l17.5,72.06c.88,3.42,1.31,5.81,1.31,7.19,0,3.67-2.15,5.5-6.44,5.5-1.75,0-3.07-.39-3.97-1.16-.9-.77-1.53-1.71-1.91-2.81-.38-1.1-.92-3.11-1.62-6.03l-17.44-72.06c-.83-3.58-1.25-5.94-1.25-7.06,0-1.75.55-3.12,1.66-4.12,1.1-1,2.68-1.5,4.72-1.5,2.46,0,4.08.71,4.88,2.12.79,1.42,1.65,4.04,2.56,7.88Z"
        fill="#64808e"
        stroke-width="0"
      />
      <path
        d="M222.13,96.15l-14.81-54.94-15,54.94c-1.17,4.17-2.09,7.16-2.78,8.97s-1.89,3.44-3.59,4.88c-1.71,1.44-3.98,2.16-6.81,2.16-2.29,0-4.18-.43-5.66-1.28-1.48-.85-2.68-2.06-3.59-3.62-.92-1.56-1.67-3.42-2.25-5.56-.58-2.15-1.1-4.13-1.56-5.97l-15.25-61.69c-.92-3.58-1.38-6.31-1.38-8.19,0-2.38.83-4.38,2.5-6,1.67-1.62,3.73-2.44,6.19-2.44,3.38,0,5.65,1.08,6.81,3.25,1.17,2.17,2.19,5.31,3.06,9.44l12,53.5,13.44-50.06c1-3.83,1.9-6.75,2.69-8.75.79-2,2.08-3.73,3.88-5.19,1.79-1.46,4.23-2.19,7.31-2.19s5.55.76,7.28,2.28c1.73,1.52,2.93,3.18,3.59,4.97.67,1.79,1.56,4.75,2.69,8.88l13.56,50.06,12-53.5c.58-2.79,1.13-4.98,1.66-6.56.52-1.58,1.42-3,2.69-4.25,1.27-1.25,3.11-1.88,5.53-1.88s4.47.8,6.16,2.41c1.69,1.6,2.53,3.62,2.53,6.03,0,1.71-.46,4.44-1.38,8.19l-15.25,61.69c-1.04,4.17-1.91,7.22-2.59,9.16s-1.85,3.64-3.5,5.09c-1.65,1.46-3.97,2.19-6.97,2.19-2.83,0-5.1-.71-6.81-2.12-1.71-1.42-2.9-3.01-3.56-4.78-.67-1.77-1.6-4.8-2.81-9.09Z"
        fill="#64808e"
        stroke-width="0"
      />
    </svg>
  );
};

export default LogoIcon;
