import { CSSProperties, ReactNode } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, CardProps, CardHeaderProps, CardContentProps } from '@mui/material';

// types
import { KeyedObject } from 'types_/state/root';
import Loading from './loading/Loading';
import { useIntl } from 'react-intl';
import LocalizedTypography from './localized-typography/LocalizedTypography';

// header style
const defaultHeaderSX = {
  p: 2,
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children: ReactNode | string;
  subheader?: ReactNode | string;
  style?: CSSProperties;
  content?: boolean;
  contentSX?: CardContentProps['sx'];
  darkTitle?: boolean;
  divider?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  elevation?: number;
  title?: ReactNode | string;
  headerSX?: CardHeaderProps['sx'];
  modal?: boolean;
  isLoading?: boolean;
  titleVariant?: any;
}

const MainCard = ({
  border = true,
  boxShadow,
  children,
  subheader,
  content = true,
  contentSX = {},
  darkTitle,
  divider = true,
  elevation,
  secondary,
  shadow,
  sx = {},
  title,
  headerSX,
  modal = false,
  isLoading,
  titleVariant = 'h5',
  ...others
}: MainCardProps) => {
  const theme = useTheme();
  const t = useIntl();
  boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

  return (
    <Card
      elevation={elevation || 0}
      {...others}
      sx={{
        position: 'relative',
        border: border ? '1px solid' : 'none',
        borderRadius: 1,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
        boxShadow:
          boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
        ':hover': {
          boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
        },
        ...(modal && {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: `calc( 100% - 50px)`, sm: 'auto' },
          '& .MuiCardContent-root': {
            overflowY: 'auto',
            minHeight: 'auto',
            maxHeight: `calc(100vh - 200px)`
          }
        }),
        ...sx
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {/* card header and action */}
          {!darkTitle && title && (
            <CardHeader
              sx={{
                ...defaultHeaderSX,
                ...headerSX
              }}
              titleTypographyProps={{ variant: 'subtitle1' }}
              title={typeof title === 'string' ? <LocalizedTypography locKey={title} variant={titleVariant} /> : title}
              action={secondary}
              subheader={subheader}
            />
          )}
          {darkTitle && title && (
            <CardHeader
              sx={{
                ...defaultHeaderSX,
                ...headerSX
              }}
              title={typeof title === 'string' ? t.formatMessage({ id: title }) : title}
              action={secondary}
            />
          )}

          {/* content & header divider */}
          {title && divider && <Divider />}

          {/* card content */}
          {content && <CardContent sx={contentSX}>{children}</CardContent>}
          {!content && children}
        </>
      )}
    </Card>
  );
};

export default MainCard;
