// material-ui

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 678.29 190.74"
        width={224}
        height={48}
      >
        <g>
          <path
            d="M27.92,27.4l17.5,72.06c.88,3.42,1.31,5.81,1.31,7.19,0,3.67-2.15,5.5-6.44,5.5-1.75,0-3.07-.39-3.97-1.16-.9-.77-1.53-1.71-1.91-2.81-.38-1.1-.92-3.11-1.62-6.03L15.36,30.09c-.83-3.58-1.25-5.94-1.25-7.06,0-1.75.55-3.12,1.66-4.12,1.1-1,2.68-1.5,4.72-1.5,2.46,0,4.08.71,4.88,2.12.79,1.42,1.65,4.04,2.56,7.88Z"
            fill="#64808e"
            stroke-width="0"
          />
          <path
            d="M63.86,27.4l17.5,72.06c.88,3.42,1.31,5.81,1.31,7.19,0,3.67-2.15,5.5-6.44,5.5-1.75,0-3.07-.39-3.97-1.16-.9-.77-1.53-1.71-1.91-2.81-.38-1.1-.92-3.11-1.62-6.03L51.29,30.09c-.83-3.58-1.25-5.94-1.25-7.06,0-1.75.55-3.12,1.66-4.12,1.1-1,2.68-1.5,4.72-1.5,2.46,0,4.08.71,4.88,2.12.79,1.42,1.65,4.04,2.56,7.88Z"
            fill="#64808e"
            stroke-width="0"
          />
          <path
            d="M159.17,96.15l-14.81-54.94-15,54.94c-1.17,4.17-2.09,7.16-2.78,8.97s-1.89,3.44-3.59,4.88c-1.71,1.44-3.98,2.16-6.81,2.16-2.29,0-4.18-.43-5.66-1.28-1.48-.85-2.68-2.06-3.59-3.62-.92-1.56-1.67-3.42-2.25-5.56-.58-2.15-1.1-4.13-1.56-5.97l-15.25-61.69c-.92-3.58-1.38-6.31-1.38-8.19,0-2.38.83-4.38,2.5-6,1.67-1.62,3.73-2.44,6.19-2.44,3.38,0,5.65,1.08,6.81,3.25,1.17,2.17,2.19,5.31,3.06,9.44l12,53.5,13.44-50.06c1-3.83,1.9-6.75,2.69-8.75.79-2,2.08-3.73,3.88-5.19,1.79-1.46,4.23-2.19,7.31-2.19s5.55.76,7.28,2.28c1.73,1.52,2.93,3.18,3.59,4.97.67,1.79,1.56,4.75,2.69,8.88l13.56,50.06,12-53.5c.58-2.79,1.13-4.98,1.66-6.56.52-1.58,1.42-3,2.69-4.25,1.27-1.25,3.11-1.88,5.53-1.88s4.47.8,6.16,2.41c1.69,1.6,2.53,3.62,2.53,6.03,0,1.71-.46,4.44-1.38,8.19l-15.25,61.69c-1.04,4.17-1.91,7.22-2.59,9.16s-1.85,3.64-3.5,5.09c-1.65,1.46-3.97,2.19-6.97,2.19-2.83,0-5.1-.71-6.81-2.12-1.71-1.42-2.9-3.01-3.56-4.78-.67-1.77-1.6-4.8-2.81-9.09Z"
            fill="#64808e"
            stroke-width="0"
          />
          <path
            d="M274.73,33.28h-41.81v22.5h38.5c2.83,0,4.95.64,6.34,1.91,1.4,1.27,2.09,2.95,2.09,5.03s-.69,3.78-2.06,5.09-3.5,1.97-6.38,1.97h-38.5v26.06h43.25c2.92,0,5.11.68,6.59,2.03,1.48,1.35,2.22,3.16,2.22,5.41s-.74,3.93-2.22,5.28c-1.48,1.35-3.68,2.03-6.59,2.03h-50.44c-4.04,0-6.95-.9-8.72-2.69-1.77-1.79-2.66-4.69-2.66-8.69V30.34c0-2.67.4-4.84,1.19-6.53.79-1.69,2.03-2.92,3.72-3.69,1.69-.77,3.84-1.16,6.47-1.16h49c2.96,0,5.16.66,6.59,1.97s2.16,3.03,2.16,5.16-.72,3.91-2.16,5.22-3.64,1.97-6.59,1.97Z"
            fill="#64808e"
            stroke-width="0"
          />
          <path
            d="M360.11,33.28h-41.81v22.5h38.5c2.83,0,4.95.64,6.34,1.91,1.4,1.27,2.09,2.95,2.09,5.03s-.69,3.78-2.06,5.09-3.5,1.97-6.38,1.97h-38.5v26.06h43.25c2.92,0,5.11.68,6.59,2.03,1.48,1.35,2.22,3.16,2.22,5.41s-.74,3.93-2.22,5.28c-1.48,1.35-3.68,2.03-6.59,2.03h-50.44c-4.04,0-6.95-.9-8.72-2.69-1.77-1.79-2.66-4.69-2.66-8.69V30.34c0-2.67.4-4.84,1.19-6.53.79-1.69,2.03-2.92,3.72-3.69,1.69-.77,3.84-1.16,6.47-1.16h49c2.96,0,5.16.66,6.59,1.97s2.16,3.03,2.16,5.16-.72,3.91-2.16,5.22-3.64,1.97-6.59,1.97Z"
            fill="#64808e"
            stroke-width="0"
          />
          <path
            d="M403.79,28.65v32.12l36.44-38c1.75-1.83,3.24-3.19,4.47-4.06,1.23-.88,2.86-1.31,4.91-1.31,2.67,0,4.82.78,6.47,2.34,1.65,1.56,2.47,3.47,2.47,5.72,0,2.71-1.4,5.4-4.19,8.06l-22.56,21.38,26,37.25c1.83,2.58,3.21,4.84,4.12,6.78.92,1.94,1.38,3.82,1.38,5.66,0,2.04-.8,3.81-2.41,5.31-1.6,1.5-3.78,2.25-6.53,2.25-2.58,0-4.69-.55-6.31-1.66s-3-2.57-4.12-4.41c-1.12-1.83-2.12-3.5-3-5l-21.94-33.75-15.19,14.56v18.94c0,3.83-.88,6.68-2.62,8.53s-4,2.78-6.75,2.78c-1.58,0-3.1-.42-4.56-1.25-1.46-.83-2.58-1.96-3.38-3.38-.58-1.17-.94-2.52-1.06-4.06-.12-1.54-.19-3.77-.19-6.69V28.65c0-3.75.82-6.56,2.47-8.44,1.65-1.88,3.88-2.81,6.72-2.81s5.05.93,6.78,2.78c1.73,1.85,2.59,4.68,2.59,8.47Z"
            fill="#64808e"
            stroke-width="0"
          />
          <path
            d="M549.48,83.09c0,5.54-1.43,10.52-4.28,14.94-2.85,4.42-7.03,7.88-12.53,10.38s-12.02,3.75-19.56,3.75c-9.04,0-16.5-1.71-22.38-5.12-4.17-2.46-7.55-5.74-10.16-9.84-2.6-4.1-3.91-8.09-3.91-11.97,0-2.25.78-4.18,2.34-5.78,1.56-1.6,3.55-2.41,5.97-2.41,1.96,0,3.61.62,4.97,1.88,1.35,1.25,2.51,3.1,3.47,5.56,1.17,2.92,2.43,5.35,3.78,7.31,1.35,1.96,3.26,3.57,5.72,4.84,2.46,1.27,5.69,1.91,9.69,1.91,5.5,0,9.97-1.28,13.41-3.84s5.16-5.76,5.16-9.59c0-3.04-.93-5.51-2.78-7.41-1.85-1.9-4.25-3.34-7.19-4.34s-6.87-2.06-11.78-3.19c-6.58-1.54-12.09-3.34-16.53-5.41s-7.96-4.88-10.56-8.44c-2.6-3.56-3.91-7.99-3.91-13.28s1.38-9.52,4.12-13.44c2.75-3.92,6.73-6.93,11.94-9.03,5.21-2.1,11.33-3.16,18.38-3.16,5.62,0,10.49.7,14.59,2.09,4.1,1.4,7.51,3.25,10.22,5.56,2.71,2.31,4.69,4.74,5.94,7.28,1.25,2.54,1.88,5.02,1.88,7.44,0,2.21-.78,4.2-2.34,5.97-1.56,1.77-3.51,2.66-5.84,2.66-2.12,0-3.74-.53-4.84-1.59-1.1-1.06-2.3-2.8-3.59-5.22-1.67-3.46-3.67-6.16-6-8.09-2.33-1.94-6.08-2.91-11.25-2.91-4.79,0-8.66,1.05-11.59,3.16-2.94,2.1-4.41,4.64-4.41,7.59,0,1.83.5,3.42,1.5,4.75,1,1.33,2.38,2.48,4.12,3.44,1.75.96,3.52,1.71,5.31,2.25,1.79.54,4.75,1.33,8.88,2.38,5.17,1.21,9.84,2.54,14.03,4,4.19,1.46,7.75,3.23,10.69,5.31,2.94,2.08,5.23,4.72,6.88,7.91,1.65,3.19,2.47,7.09,2.47,11.72Z"
            fill="#64808e"
            stroke-width="0"
          />
        </g>
        <text
          transform="translate(76.29 177.29)"
          fill="#64808e"
          font-family="ArialRoundedMTBold, 'Arial Rounded MT Bold'"
          font-size="48"
        >
          <tspan x="0" y="0">
            Soft
          </tspan>
          <tspan x="93.98" y="0" letter-spacing="-.01em">
            w
          </tspan>
          <tspan x="132.38" y="0">
            a
          </tspan>
          <tspan x="160.9" y="0" letter-spacing="-.02em">
            r
          </tspan>
          <tspan x="180.73" y="0">
            e Solutions
          </tspan>
        </text>
      </svg>
    </>
  );
};

export default LogoMain;
