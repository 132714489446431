import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { CHARGE_POINTS_ROUTES, MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const ChargePointsList = Loadable(lazy(() => import('pages/charge-points/screens/list/ChargePointsList')));
const ChargePointCreate = Loadable(lazy(() => import('pages/charge-points/screens/create/ChargePointCreate')));
const ChargePointEdit = Loadable(lazy(() => import('pages/charge-points/screens/edit/ChargePointEdit')));
const ChargePointDetails = Loadable(lazy(() => import('pages/charge-points/screens/details/ChargePointDetails')));
const ChargePointPitStop = Loadable(lazy(() => import('pages/charge-points/screens/pit-stop/ChargePointPitStop')));

// ==============================|| MAIN ROUTING ||============================== //

const ChargePointsRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.CHARGE_POINTS,
      children: [
        {
          path: CHARGE_POINTS_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CHARGE_POINT_READ]}>
              <ChargePointsList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CHARGE_POINTS_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CHARGE_POINT_WRITE]}>
              <ChargePointCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CHARGE_POINTS_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CHARGE_POINT_WRITE]}>
              <ChargePointEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CHARGE_POINTS_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CHARGE_POINT_READ]}>
              <ChargePointDetails />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CHARGE_POINTS_ROUTES.PIT_STOP,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CP_COMMAND_WRITE]}>
              <ChargePointPitStop />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default ChargePointsRoutes;
