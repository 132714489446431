import useAuth from 'hooks/useAuth';
import { createContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { UserAppInitUserDto, useLazyUserAppInitQuery } from 'store/api/generated/user-api';
import { Roles } from 'types/roles';
import { CpoType, PermissionContextType } from 'types_/state/permission';
import { replaceCurrentOperatorParam } from 'utils/replaceCurrentOperatorParam';
import {
  useLazyUserGetTableFiltersQuery,
  useUserUpdateTableFilterMutation,
  useUserDeleteTableFiltersMutation
} from 'store/api/generated/user-api';
import { SelectedFilterValues } from 'components/table-operations';
import { io, Socket } from 'socket.io-client';
import { userPool } from './CognitoContext';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import useConfig from 'hooks/useConfig';
import { I18n, ThemeMode } from 'types/config';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_HTTP_COMMON_BASE_URL;

const INITIAL_CPO: CpoType = {
  companyName: '',
  group: '',
  _id: ''
};
const INITIAL_CONTEXT: PermissionContextType = {
  cpoId: '',
  selectedOperator: INITIAL_CPO,
  operatorList: [],
  permissionsInitialized: false,
  isRoot: false,
  roles: [],
  userData: undefined,
  presetName: '',
  userTableFilters: null,
  handleOperatorChange: () => {},
  handleOperatorParamChange: () => {},
  saveFilter: (table: string, name: string, values: SelectedFilterValues) => {},
  deleteSavedFilter: (id: string, table: string) => {},
  socket: io(URL ?? '', {
    autoConnect: false
  })
};

const PermissionContext = createContext<PermissionContextType>(INITIAL_CONTEXT);

export const PermissionProvider = ({ children }: { children: React.ReactElement }) => {
  const [getUserInitInfo, { data, isSuccess }] = useLazyUserAppInitQuery();
  const [roles, setRoles] = useState<Roles[]>([]);
  const [userData, setUserData] = useState<UserAppInitUserDto>();
  const [operators, setOperators] = useState<CpoType[]>([]);
  const [permissionsInitialized, setPermissionsInitialized] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState<CpoType>(INITIAL_CPO);
  const [isRoot, setIsRoot] = useState(false);
  const [presetName, setPresetName] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [deleteFilterTrigger] = useUserDeleteTableFiltersMutation();
  const [saveFilterTrigger] = useUserUpdateTableFilterMutation();
  const [getFiltersTrigger] = useLazyUserGetTableFiltersQuery();
  const [userTableFilters, setUserTableFilters] = useState({});
  const socket = useRef<Socket>();
  const { onChangeMode, onChangeLocalization } = useConfig();

  const { isLoggedIn, isInitialized, operatorId } = useAuth();

  const { preset, cpoList, user, tableFilters } = data || {};

  useEffect(() => {
    if (isLoggedIn && isInitialized && operatorId) {
      getUserInitInfo({ cpoId: operatorId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isInitialized, operatorId]);

  useEffect(() => {
    if (preset && user && isSuccess) {
      setRoles(preset.roles as Roles[]);
      setPresetName(preset.name);
      setUserData(user as UserAppInitUserDto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, preset, setRoles]);

  useEffect(() => {
    if (cpoList && isSuccess) {
      setOperators(cpoList);
      const rootOperator = cpoList.find((operator) => operator.group === 'root');
      const selectedOperator = rootOperator ? rootOperator : cpoList[0];
      setSelectedOperator(selectedOperator);
      setPermissionsInitialized(true);
      setIsRoot(rootOperator ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpoList && isSuccess]);

  useEffect(() => {
    if (tableFilters && isSuccess) {
      const obj = {};

      for (const table of tableFilters) {
        // @ts-expect-error
        obj[table._id] = table.filters;
      }

      setUserTableFilters(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters && isSuccess]);

  useEffect(() => {
    if (user && isSuccess) {
      const lang = user.lang as I18n;
      const theme = user.theme as ThemeMode;

      // get rid of the setTimeout
      setTimeout(() => onChangeLocalization(lang ? lang : 'tr'), 400);
      setTimeout(() => onChangeMode(theme ? theme : 'light'), 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user && isSuccess]);

  useEffect(() => {
    function onConnect() {
      console.log('Socket Connected');
    }

    function onDisconnect() {
      console.log('Socket Disconnected');
    }

    if (socket.current) {
      socket.current.on('connect', onConnect);
      socket.current.on('disconnect', onDisconnect);
    }

    return () => {
      if (socket.current) {
        socket.current.off('connect', onConnect);
        socket.current.off('disconnect', onDisconnect);
      }
    };
  }, [socket]);

  useEffect(() => {
    if (isLoggedIn) {
      handleSocketConnection();
    }
  }, [isLoggedIn]);

  const handleSocketConnection = () => {
    userPool.getCurrentUser()?.getSession((err: any, ses: CognitoUserSession) => {
      if (err) {
      } else {
        const accesTokenFromSession = ses?.getAccessToken().getJwtToken();
        const idTokenFromSession = ses?.getIdToken().getJwtToken();
        socket.current = io(URL ?? '', {
          auth: { accessToken: accesTokenFromSession, idToken: idTokenFromSession },
          autoConnect: false
        });
      }
    });
  };

  const handleOperatorChange = (id: string) => {
    const selectedOperator = operators.find((operator) => operator._id === id);

    const newUrl = replaceCurrentOperatorParam(selectedOperator, location.pathname);

    if (newUrl !== location.pathname) {
      navigate(newUrl);
    }
  };

  const handleOperatorParamChange = (id: string) => {
    const selectedOperator = operators.find((operator) => operator._id === id);

    if (!selectedOperator) {
      return navigate('/maintenance/404');
    }

    setSelectedOperator(selectedOperator);

    if (selectedOperator?.group === 'root') {
      setIsRoot(true);
    } else {
      setIsRoot(false);
    }
  };

  const saveFilter = async (table: string, name: string, values: SelectedFilterValues) => {
    await saveFilterTrigger({
      cpoId: selectedOperator._id,
      updateUserTableFilterDto: {
        table: table,
        name: name,
        value: values
      }
    });

    const savedFilters = await getFiltersTrigger({ cpoId: selectedOperator._id, table: table });
    setUserTableFilters((prevState) => ({ ...prevState, [table]: savedFilters.data }));
  };

  const deleteSavedFilter = async (id: string, table: string) => {
    await deleteFilterTrigger({
      cpoId: selectedOperator._id,
      id
    });

    const savedFilters = await getFiltersTrigger({ cpoId: selectedOperator._id, table: table });
    setUserTableFilters((prevState) => ({ ...prevState, [table]: savedFilters.data }));
  };

  useEffect(() => {
    if (location.pathname === '/' && selectedOperator._id) {
      navigate(`/cpo/${selectedOperator._id}/dashboard`);
    }
  }, [location.pathname, selectedOperator, navigate]);

  useEffect(() => {
    if (!isLoggedIn) {
      setOperators([]);
      setPermissionsInitialized(false);
    }
  }, [isLoggedIn]);

  return (
    <PermissionContext.Provider
      value={{
        roles,
        userData,
        isRoot,
        presetName,
        permissionsInitialized,
        cpoId: selectedOperator._id,
        selectedOperator: selectedOperator,
        operatorList: operators,
        handleOperatorChange,
        handleOperatorParamChange,
        userTableFilters,
        saveFilter,
        deleteSavedFilter,
        socket: socket.current ?? INITIAL_CONTEXT.socket
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContext;
