import { isPending } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { setLoading } from 'store/reducers/globalUI';
import { silentCallEndpoints } from './ActionConstants';

export const LoadingMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isPending(action)) {
    const IsActionSilentCall = silentCallEndpoints.some((endpoint) => endpoint === action.meta.arg.endpointName);

    if (!IsActionSilentCall) {
      api.dispatch(setLoading());
    }
  }

  return next(action);
};
