import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const CpSettings = Loadable(lazy(() => import('pages/epdk/screens/CpSettings')));
const Logs = Loadable(lazy(() => import('pages/epdk/screens/Logs')));
// ==============================|| MAIN ROUTING ||============================== //

const EPDKRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <MainLayout />
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.EPDK,
      children: [
        {
          path: 'cp-settings',
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CP_LOG_READ]}>
              <CpSettings />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: 'logs',
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CP_LOG_READ]}>
              <Logs />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default EPDKRoutes;
