import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { MAIN_ROUTES, ROLES_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';
import { Roles } from 'types/roles';

const RolesList = Loadable(lazy(() => import('pages/roles/screens/list/RolesList')));
const RolesCreate = Loadable(lazy(() => import('pages/roles/screens/create/RolesCreate')));
const RoleEdit = Loadable(lazy(() => import('pages/roles/screens/edit/RoleEdit')));
const RoleDetail = Loadable(lazy(() => import('pages/roles/screens/detail/RoleDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const RolesRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <MainLayout />
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.ROLES,
      children: [
        {
          path: ROLES_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.ROLE_READ]}>
              <RolesList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: ROLES_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.ROLE_WRITE]}>
              <RolesCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: ROLES_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.ROLE_WRITE]}>
              <RoleEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: ROLES_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.ROLE_READ]}>
              <RoleDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default RolesRoutes;
