import usePermission from 'hooks/usePermission';
import { useNavigate } from 'react-router';
import {
  MAIN_ROUTES,
  CONNECTOR_ROUTES,
  TARIFFS_ROUTES,
  HARDWARE_ROUTES,
  FACILITIES_ROUTES,
  ROLES_ROUTES,
  AUTH_ROUTES,
  USERS_ROUTES,
  CPO_ROUTES,
  LOCATIONS_ROUTES,
  CHARGE_POINTS_ROUTES,
  CHARGE_OPERATIONS_ROUTES,
  RFID_ROUTES,
  CUSTOMERS_ROUTES,
  BILL_ROUTES,
  PROFILE_ROUTES
} from 'types/routes';

function useAppNavigate() {
  const navigate = useNavigate();
  const { selectedOperator } = usePermission();

  const convertIdToUrl = (link: string, _id?: string, cpoId?: string) => {
    let replacedLink = link.replace(':id', _id ?? '');

    return replacedLink;
  };

  const convertCpoIdToUrl = (link: string, cpoId?: string) => {
    let replacedLink = link.replace(':cpoId', cpoId ? cpoId : selectedOperator._id ?? '');

    return replacedLink;
  };

  const global = {
    goBack: () => navigate(-1)
  };

  //#region charge-operations
  const navigateToChargeOperationsList = (): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.CHARGE_OPERATIONS)}/${CHARGE_OPERATIONS_ROUTES.LIST}`;

    navigate(url);
  };

  const navigateToChargeOperationsDetails = (_id?: string, cpoId?: string): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.CHARGE_OPERATIONS, cpoId)}/${convertIdToUrl(
      CHARGE_OPERATIONS_ROUTES.DETAIL,
      _id
    )}`;

    navigate(url);
  };

  const goToChargeOperation = {
    list: navigateToChargeOperationsList,
    details: navigateToChargeOperationsDetails
  };
  //#endregion

  //#region charge-points
  const navigateToChargePointList = (): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.CHARGE_POINTS)}/${CHARGE_POINTS_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToChargePointCreate = (): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.CHARGE_POINTS)}/${CHARGE_POINTS_ROUTES.CREATE}`;

    navigate(url);
  };
  const navigateToChargePointEdit = (_id?: string): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.CHARGE_POINTS)}/${convertIdToUrl(CHARGE_POINTS_ROUTES.EDIT, _id)}`;

    navigate(url);
  };
  const navigateToChargePointDetails = (_id?: string, cpoId?: string, openInNew?: boolean): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.CHARGE_POINTS, cpoId)}/${convertIdToUrl(
      CHARGE_POINTS_ROUTES.DETAIL,
      _id
    )}`;

    if (openInNew) {
      window.open(url);
      return;
    }

    navigate(url);
  };
  const navigateToChargePointPitStop = (_id?: string): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.CHARGE_POINTS)}/${convertIdToUrl(CHARGE_POINTS_ROUTES.PIT_STOP, _id)}`;

    navigate(url);
  };

  const goToChargePoint = {
    list: navigateToChargePointList,
    create: navigateToChargePointCreate,
    edit: navigateToChargePointEdit,
    details: navigateToChargePointDetails,
    pitStop: navigateToChargePointPitStop
  };
  //#endregion

  //#region connector
  const navigateToConnectorList = (): void => {
    const url = `${MAIN_ROUTES.CONNECTORS}/${CONNECTOR_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToConnectorCreate = (): void => {
    const url = `${MAIN_ROUTES.CONNECTORS}/${CONNECTOR_ROUTES.CREATE}`;

    navigate(url);
  };
  const navigateToConnectorEdit = (_id?: string): void => {
    const url = `${MAIN_ROUTES.CONNECTORS}/${convertIdToUrl(CONNECTOR_ROUTES.EDIT, _id)}`;

    navigate(url);
  };
  const navigateToConnectorDetails = (_id?: string): void => {
    const url = `${MAIN_ROUTES.CONNECTORS}/${convertIdToUrl(CONNECTOR_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };

  const goToConnector = {
    list: navigateToConnectorList,
    create: navigateToConnectorCreate,
    edit: navigateToConnectorEdit,
    details: navigateToConnectorDetails
  };
  //#endregion

  //#region customer
  const navigateToCustomerDetails = (_id?: string): void => {
    const url = `${MAIN_ROUTES.CUSTOMERS}/${convertIdToUrl(CUSTOMERS_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };

  const goToCustomer = {
    details: navigateToCustomerDetails
  };
  //#endregion

  //#region cpo
  const navigateToCpoList = (): void => {
    const url = `${MAIN_ROUTES.CPOS}/${CPO_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToCpoCreate = (): void => {
    const url = `${MAIN_ROUTES.CPOS}/${CPO_ROUTES.CREATE}`;

    navigate(url);
  };
  const navigateToCpoEdit = (_id?: string): void => {
    const url = `${MAIN_ROUTES.CPOS}/${convertIdToUrl(CPO_ROUTES.EDIT, _id)}`;

    navigate(url);
  };
  const navigateToCpoDetails = (_id?: string): void => {
    const url = `${MAIN_ROUTES.CPOS}/${convertIdToUrl(CPO_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };

  const goToCpo = {
    list: navigateToCpoList,
    create: navigateToCpoCreate,
    edit: navigateToCpoEdit,
    details: navigateToCpoDetails
  };
  //#endregion

  //#region customers

  //#endregion

  //#region facilities
  const navigateToFacilitiesList = (): void => {
    const url = `${MAIN_ROUTES.FACILITIES}/${FACILITIES_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToFacilityCreate = (): void => {
    const url = `${MAIN_ROUTES.FACILITIES}/${FACILITIES_ROUTES.CREATE}`;

    navigate(url);
  };
  const navigateToFacilityEdit = (_id?: string): void => {
    const url = `${MAIN_ROUTES.FACILITIES}/${convertIdToUrl(FACILITIES_ROUTES.EDIT, _id)}`;

    navigate(url);
  };
  const navigateToFacilityDetails = (_id?: string): void => {
    const url = `${MAIN_ROUTES.FACILITIES}/${convertIdToUrl(FACILITIES_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };

  const goToFacilities = {
    list: navigateToFacilitiesList,
    create: navigateToFacilityCreate,
    edit: navigateToFacilityEdit,
    details: navigateToFacilityDetails
  };
  //#endregion

  //#region hardwares
  const navigateToHardwareList = (): void => {
    const url = `${MAIN_ROUTES.HARDWARES}/${HARDWARE_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToHardwareCreate = (): void => {
    const url = `${MAIN_ROUTES.HARDWARES}/${HARDWARE_ROUTES.CREATE}`;

    navigate(url);
  };
  const navigateToHardwareEdit = (_id?: string): void => {
    const url = `${MAIN_ROUTES.HARDWARES}/${convertIdToUrl(HARDWARE_ROUTES.EDIT, _id)}`;

    navigate(url);
  };
  const navigateToHardwareDetails = (_id?: string): void => {
    const url = `${MAIN_ROUTES.HARDWARES}/${convertIdToUrl(HARDWARE_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };

  const goToHardware = {
    list: navigateToHardwareList,
    create: navigateToHardwareCreate,
    edit: navigateToHardwareEdit,
    details: navigateToHardwareDetails
  };
  //#endregion

  //#region locations
  const navigateToLocationList = (openInNew?: boolean): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.LOCATIONS)}/${LOCATIONS_ROUTES.LIST}`;

    if (openInNew) {
      window.open(url);
      return;
    }
    navigate(url);
  };
  const navigateToLocationCreate = (): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.LOCATIONS)}/${LOCATIONS_ROUTES.CREATE}`;

    navigate(url);
  };
  const navigateToLocationEdit = (_id?: string): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.LOCATIONS)}/${convertIdToUrl(LOCATIONS_ROUTES.EDIT, _id)}`;

    navigate(url);
  };
  const navigateToLocationDetails = (_id?: string, cpoId?: string, openInNew?: boolean): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.LOCATIONS, cpoId)}/${convertIdToUrl(
      LOCATIONS_ROUTES.DETAIL,
      _id,
      cpoId
    )}`;

    if (openInNew) {
      window.open(url);
      return;
    }

    navigate(url);
  };

  const goToLocation = {
    list: navigateToLocationList,
    create: navigateToLocationCreate,
    edit: navigateToLocationEdit,
    details: navigateToLocationDetails
  };
  //#endregion

  //#region auth
  const navigateToLogin = (): void => {
    const url = `/${AUTH_ROUTES.LOGIN}`;
    navigate(url, { replace: true });
  };
  const navigateToRegister = (): void => {
    const url = `/${AUTH_ROUTES.REGISTER}`;
    navigate(url);
  };
  const navigateToChangePassword = (): void => {
    const url = `/${AUTH_ROUTES.CHANGE_PASSWORD}`;
    navigate(url);
  };
  const navigateToForgotPassword = (): void => {
    const url = `/${AUTH_ROUTES.FORGOT_PASSWORD}`;
    navigate(url);
  };
  const navigateToCheckMail = (): void => {
    const url = `/${AUTH_ROUTES.CHECK_MAIL}`;
    navigate(url);
  };
  const navigateToResetPassword = (): void => {
    const url = `/${AUTH_ROUTES.RESET_PASSWORD}`;
    navigate(url);
  };
  const navigateToCodeVerification = (): void => {
    const url = `/${AUTH_ROUTES.CODE_VERIFICATION}`;
    navigate(url);
  };

  const goToAuth = {
    login: navigateToLogin,
    register: navigateToRegister,
    changePassword: navigateToChangePassword,
    forgotPassword: navigateToForgotPassword,
    checkMail: navigateToCheckMail,
    resetPassword: navigateToResetPassword,
    codeVerification: navigateToCodeVerification
  };
  //#endregion

  //#region reservations

  //#endregion

  //#region roles
  const navigateToRoleList = (): void => {
    const url = `${MAIN_ROUTES.ROLES}/${ROLES_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToRoleCreate = (): void => {
    const url = `${MAIN_ROUTES.ROLES}/${ROLES_ROUTES.CREATE}`;

    navigate(url);
  };
  const navigateToRoleEdit = (_id?: string): void => {
    const url = `${MAIN_ROUTES.ROLES}/${convertIdToUrl(ROLES_ROUTES.EDIT, _id)}`;

    navigate(url);
  };
  const navigateToRoleDetails = (_id?: string): void => {
    const url = `${MAIN_ROUTES.ROLES}/${convertIdToUrl(ROLES_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };

  const goToRoles = {
    list: navigateToRoleList,
    create: navigateToRoleCreate,
    edit: navigateToRoleEdit,
    details: navigateToRoleDetails
  };
  //#endregion

  //#region tariff
  const navigateToTariffList = (): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.TARIFFS)}/${TARIFFS_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToTariffCreate = (): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.TARIFFS)}/${TARIFFS_ROUTES.CREATE}`;

    navigate(url);
  };
  const navigateToTariffEdit = (_id?: string): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.TARIFFS)}/${convertIdToUrl(TARIFFS_ROUTES.EDIT, _id)}`;

    navigate(url);
  };
  const navigateToTariffDetails = (_id?: string, cpoId?: string): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.TARIFFS, cpoId)}/${convertIdToUrl(TARIFFS_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };

  const goToTariff = {
    list: navigateToTariffList,
    create: navigateToTariffCreate,
    edit: navigateToTariffEdit,
    details: navigateToTariffDetails
  };
  //#endregion

  //#region users
  const navigateToUsersList = (): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.USERS)}/${USERS_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToUserCreate = (): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.USERS)}/${USERS_ROUTES.CREATE}`;

    navigate(url);
  };
  const navigateToUserEdit = (_id?: string): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.USERS)}/${convertIdToUrl(USERS_ROUTES.EDIT, _id)}`;

    navigate(url);
  };
  const navigateToUserDetails = (_id?: string, cpoId?: string): void => {
    const url = `${convertCpoIdToUrl(MAIN_ROUTES.USERS, cpoId)}/${convertIdToUrl(USERS_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };

  const goToUser = {
    list: navigateToUsersList,
    create: navigateToUserCreate,
    edit: navigateToUserEdit,
    details: navigateToUserDetails
  };
  //#endregion

  //#region tariff
  const navigateToRfidList = (): void => {
    const url = `${MAIN_ROUTES.RFID}/${RFID_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToRfidCreate = (): void => {
    const url = `${MAIN_ROUTES.RFID}/${RFID_ROUTES.CREATE}`;

    navigate(url);
  };

  const navigateToRfidDetails = (_id?: string): void => {
    const url = `${MAIN_ROUTES.RFID}/${convertIdToUrl(RFID_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };

  const goToRfid = {
    list: navigateToRfidList,
    create: navigateToRfidCreate,
    details: navigateToRfidDetails
  };
  //#endregion

  //#region Bills
  const navigateToBillList = (): void => {
    const url = `${MAIN_ROUTES.BILLS}/${BILL_ROUTES.LIST}`;

    navigate(url);
  };
  const navigateToBillDetail = (_id?: string): void => {
    const url = `${MAIN_ROUTES.BILLS}/${convertIdToUrl(BILL_ROUTES.DETAIL, _id)}`;

    navigate(url);
  };
  const goToBill = {
    list: navigateToBillList,
    details: navigateToBillDetail
  };

  //#endregion

  //#region Profile
  const navigateToProfile = (): void => {
    const url = `${MAIN_ROUTES.PROFILE}/${PROFILE_ROUTES.PERSONAL}`;

    navigate(url);
  };

  const goToProfile = {
    details: navigateToProfile
  };

  //#endregion

  return {
    global,
    goToAuth,
    goToConnector,
    goToTariff,
    goToHardware,
    goToFacilities,
    goToRoles,
    goToUser,
    goToCpo,
    goToLocation,
    goToChargePoint,
    goToChargeOperation,
    goToRfid,
    goToCustomer,
    goToBill,
    goToProfile
  };
}

export { useAppNavigate };
