import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { CONNECTOR_ROUTES, MAIN_ROUTES } from 'types/routes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import { Roles } from 'types/roles';
import { RoleBasedRouteGuard, RootRouteGuard } from 'utils/route-guard/RoleBasedGuard';

const ConnectorsList = Loadable(lazy(() => import('pages/connectors/screens/list/ConnectorsList')));
const ConnectorCreate = Loadable(lazy(() => import('pages/connectors/screens/create/ConnectorCreate')));
const ConnectorEdit = Loadable(lazy(() => import('pages/connectors/screens/edit/ConnectorEdit')));
const ConnectorDetail = Loadable(lazy(() => import('pages/connectors/screens/detail/ConnectorDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const ConnectorRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <RootRouteGuard>
        <MainLayout />
      </RootRouteGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MAIN_ROUTES.CONNECTORS,
      children: [
        {
          path: CONNECTOR_ROUTES.LIST,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CONNECTOR_TYPE_READ]}>
              <ConnectorsList />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CONNECTOR_ROUTES.CREATE,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CONNECTOR_TYPE_WRITE]}>
              <ConnectorCreate />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CONNECTOR_ROUTES.EDIT,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CONNECTOR_TYPE_WRITE]}>
              <ConnectorEdit />
            </RoleBasedRouteGuard>
          )
        },
        {
          path: CONNECTOR_ROUTES.DETAIL,
          element: (
            <RoleBasedRouteGuard accessibleRoles={[Roles.CONNECTOR_TYPE_READ]}>
              <ConnectorDetail />
            </RoleBasedRouteGuard>
          )
        }
      ]
    }
  ]
};

export default ConnectorRoutes;
