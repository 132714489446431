import { useRoutes } from 'react-router-dom';

// project import
import DashboardRoutes from './DashboardRoutes';
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import ChargePointsRoutes from './ChargePointsRoutes';
import ChargeOperationsRoutes from './ChargeOperationsRoutes';
import LocationsRoutes from './LocationsRoutes';
import TariffsRoutes from './TariffRoutes';
import ReservationRoutes from './ReservationRoutes';
import CpoRoutes from './CpoRoutes';
import UsersRoutes from './UsersRoutes';
import RolesRoutes from './RolesRoutes';
import HardwareRoutes from './HardwareRoutes';
import ConnectorRoutes from './ConnectorRoutes';
import FacilitiesRoutes from './FacilitiesRoutes';
import CustomerRoutes from './CustomersRoutes';
import SettingsRoutes from './SettingsRoutes';
import EPDKRoutes from './EPDKRoutes';
import RfidRoutes from './RfidRoutes';
import BillRoutes from './BillRoutes';
import ProfileRoutes from './ProfileRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
  return useRoutes([
    DashboardRoutes,
    AuthRoutes,
    MainRoutes,
    ChargePointsRoutes,
    ChargeOperationsRoutes,
    LocationsRoutes,
    TariffsRoutes,
    ReservationRoutes,
    CpoRoutes,
    UsersRoutes,
    RolesRoutes,
    HardwareRoutes,
    ConnectorRoutes,
    FacilitiesRoutes,
    CustomerRoutes,
    SettingsRoutes,
    EPDKRoutes,
    RfidRoutes,
    BillRoutes,
    ProfileRoutes
  ]);
}
